import { Block, BlockFaq } from '@ict-trust/dgt-blocks/src/types/_blocks.types';

import { defaultLocale } from '../utils/language';

type LdJsonScript = {
  type: string;
  content: Record<string, unknown>;
};

type LocationMeta = {
  image: string;
  id: string;
  streetAddress: string;
  addressLocality: string;
  addressRegion?: string;
  postalCode: string;
  addressCountry: string;
  latitude: number;
  longitude: number;
  openingHoursSpecification?: Record<string, string>[];
};

type HreflangTag = {
  hreflang: string;
  href: string;
};

type HreflangPagesTags = {
  [key: string]: Array<HreflangTag>;
};

type TVSeriesMeta = {
  actor: {
    name: string;
  }[];
  author: {
    name: string;
  };
  name: string;
  containsSeason: {
    datePublished: string;
    episode: {
      episodeNumber: string;
      name: string;
    };
    name: string;
    numberOfEpisodes: string;
  }[];
};

const typesOfImageMeta = {
  domesticPetTransport: {
    content: {
      name: 'Domestic Pet Transport',
      description:
        "Dogtainers' domestic pet transport services provide you with quality service and flexibility to suit your pet's circumstances. We offer both air pet transport and road pet transport. ",
      publisher: {
        '@type': 'Organization',
        name: 'Dogtainers',
        url: 'https://www.dogtainers.com.au/domestic-pet-transport/',
        image:
          'https://www.dogtainers.com.au/static/182786ed6fd6caff9a9c3dc60b8e3473/12b72/dogtainers_van.webp',
      },
    },
  },
  domesticRoadPetTransport: {
    content: {
      name: 'Domestic Road Pet Transport',
      description:
        "Dogtainers' domestic road transport services provide you with quality service and flexibility to suit your pet's circumstances. Our road transportation is available from all major cities across Australia to help your furry friend make their journey.",
      publisher: {
        '@type': 'Organization',
        name: 'Dogtainers',
        url: 'https://www.dogtainers.com.au/domestic-road-pet-transport/',
        image:
          'https://www.dogtainers.com.au/static/08e2a5d7a5df68d01a26fb5a81f1473a/31706/InternationalDogTransport.webp',
      },
    },
  },
  internationalPetTransportPetTransportNewZealand: {
    content: {
      name: 'International Pet Transport New Zealand ',
      description:
        'Dogtainers has offices all across Australia and New Zealand, so our team will take care of your pet at both ends of your journey. We now have office locations on the north island and south island.',
      publisher: {
        '@type': 'Organization',
        name: 'Dogtainers',
        url: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-new-zealand/"',
        image:
          'https://www.dogtainers.com.au/static/be092d0d70952050e94803f7d63b4265/9c00f/NZ_wlogo.webp',
      },
    },
  },
} as Record<string, Pick<LdJsonScript, 'content'>>;

const typesOfWebSchema = {
  virgin: {
    name: 'Virgin Pet Travel',
    description:
      'Virgin Pet Travel Australia is one of the most trusted airlines that provides pet air travel services. Virgin currently requires all guests wishing to book travel with their pet to make a booking with a Commercial Pet Transport Company, like Dogtainers. ',
    publisherName: 'Dogtainers Pet Travel Virgin Airlines',
  },
  airlines: {
    name: 'Pet Travel Airlines',
    description:
      'Pet-friendly airlines have updated their pet travel policy, all bookings for pet and animal transport must be made through a Freight-approved pet travel specialist. We’re a proud airline pet travel partner for Virgin and Qantas, and happy to organise all aspects of your pet travel needs. ',
    publisherName: 'Pet Travel Airlines',
  },
  qantas: {
    name: 'Qantas Pet Travel',
    description:
      'Qantas is a pet-friendly airline that offers both domestic and international flights. They recently changed their pet travel policy, so now all bookings for domestic pet and animal transport must be made through a Qantas Freight-approved pet travel specialist. ',
    publisherName: 'Dogtainers Pet Travel Qantas Airlines',
  },
  animalsAboardTvSeries: {
    name: 'Animals Aboard TV Series',
    description:
      'Dive into captivating stories of animal transport by Dogtainers on the Animals Aboard TV series. Uncover captivating and untold stories with Dr Harry.. ',
    publisherName: 'Dogtainers',
  },
} as const;

const typesOfFAQ = {
  virgin: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How to Prep Your Pets for Travel with Virgin Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Your pet will be in their container from check-in to when they’re picked up at their destination. For safety reasons, Virgin Australia will not release your pet from their container. Your pet must be fit and healthy and able to cope with being confined for an extended period of time. Before you present your pet for check-in, ensure they are well-hydrated and have had some time to exercise. It is also recommended that pets travel on an empty stomach and that their food is limited before travel. If your pet soils the container before departure, you may be required to return to the pet check-in area to clean the container. Otherwise, Virgin may be unable to load your pet onto the aircraft.',
        },
      },
      {
        '@type': 'Question',
        name: 'Where Should I Drop Off My Pets for a Virgin Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The Dogtainers team can assist with pick up and drop off and ensure your pet safely arrives at their destination. You may also choose to pick up and drop off your pet at the various Virgin freight areas.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can Brachycephalic (snub-nosed / flat-faced) Breeds Travel with Virgin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Brachycephalic breeds, such as pugs, are required to be transported by a pet transport specialist, like Dogtainers. We are a Virgin-approved Brachycephalic transport specialist and can advise you on all the requirements brachy breeds need to meet.   To ensure brachy pets are fit for flight, we can organise a Brachycephalic Obstructive Airway Syndrome (BOAS) test with one of our qualified vets, or your preferred reputable vet. Mixed breeds aren’t required to take a BOAS test.',
        },
      },
      {
        '@type': 'Question',
        name: 'Which Breeds Can’t Fly with Virgin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Australia has a list of “dangerous dog breeds” that are prohibited from flying in Australia. They cannot be imported into Australia, and if they’re already here, they can not fly domestically.  These breeds are not allowed to fly in Australia: American Pit Bull and other Pit Bull Breeds Fila Brasileiro Japanese Tosa Dogo Argentino Perro de Presa Canario  Some domestic/non-domestic hybrids (e.g. dog-wolf crosses) are also prohibited under the “Environment Protection and Biodiversity Conservation Act 1999”. Czechoslovakian wolfdog or Czechoslovakian Vlcak Saarloos wolfdog or Saarloos wolfhound Lupo Italiano or Italian wolfdog Kunming wolfdog or Kunming dog.  We don’t judge a dog by its breed, we know each dog has their own personality, and just because they’re a ‘dangerous breed’, it doesn’t mean they’re dangerous. So long as your dog isn’t aggressive, we can transport all dog breeds by road in one of our specialist pet transportation vans.',
        },
      },
      {
        '@type': 'Question',
        name: 'Will I Need a Permit For My Pet with Virgin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. Different states and countries have varying requirements for pet transport, including the need for a permit. Unfortunately, the airlines won’t tell you which ones apply to you. Luckily, we will! As part of our service, we ensure you’re informed about any and all vaccines, permits, and other requirements your pet must meet in order to travel to their destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is it Like for Pets to Travel in Virgin’s Pet Cargo?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets travel in a pressurised, temperature-controlled, dimly lit compartment, which is usually at the rear of the plane. In this section, travel crates are safely strapped into position by trained airline staff. The captain of the aircraft sets the temperature to around 18 degrees Celsius. With the gentle hum of the aircraft, pets often spend time dozing before reaching their destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Animals Does Virgin Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Virgin can transport various pets, including dogs (not racing greyhounds), cats, rabbits, guinea pigs, domestic fish with no aeration requirements that aren’t intended for resale, and domestic birds that don’t need a permit to travel (maximum four birds per cage).',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I be Booked on the Same Flight as My Animal?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. There are only minimal spots on a flight for pets, whereas there are hundreds of flight seats for people. For the best chance of being on the same flight as your pet, it’s best to book your pet’s flight first. However, this does not guarantee you’ll be together, it just gives you better odds.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens if My Pet’s Flight is Delayed, or Cancelled?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Delayed - The airline must notify us if the delay is a few hours long. Our team then goes to the airport to check on the animal and ensure they’re doing well.  Cancelled - If a Virgin domestic flight is cancelled, our team will contact you to confirm the alternative flight you’d like to book. Depending on what you want to do, we can go pick up your pet and bring them back to our office, to boarding, or back to your residence.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens When My Pet’s Flight Lands?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'For safety reasons, animals are first to be loaded off the aircraft. Pets are then brought to the Virgin Freight Terminal and are usually ready for collection at least 30 minutes after flight arrival. Owners need to have their ID and airway bill (digital or printed) to collect the pet. Alternatively, Dogtainers can arrange the pickup and drop off of your furbaby either at one of our offices or at a location of your choice.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Get Notified About My Pet’s Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Unfortunately, airport staff only send updates for international flights. If you’d like to track your pet’s domestic flight, you can Google their flight number, or check on their Virgin Flight Status. If you’ve elected to have Dogtainers drop off or pick up your pet from the airport, we can send you updates personally, so you know how your fur baby is doing.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Many Pet Flights Were Booked with Virgin Last Year?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Dogtainers and Virgin have a strong relationship, and in 2021, we arranged Virgin flights for more than 11,900 pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Pack Medications or Special Food for My Pet on a Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Airline regulations state that we cannot send food with your pet when they travel. We can send medications with your pets if they are identified by the vet and clearly state the content. However, the airline reserves the right to refuse the medication.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Need to Measure My Pet for Their Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Be sure to weigh and measure your pet correctly. If at the time of lodgement there are any weight discrepancies, or a larger crate is required, your pet may be offloaded, and additional charges will be incurred. Learn more about crates and measuring your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'Should I Feed My Pet Before They Fly?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We recommend not feeding your pet within 8 hours of travel as this can cause them to have an accident inside the crate. Although it is natural for your pet to answer nature’s call, if your pet has an accident in their crate before their flight, the airline will refuse to put them on the flight until a Dogtainers representative can clean the pet and inside the crate. This can then result in flights being missed, and additional charges may apply.',
        },
      },
      {
        '@type': 'Question',
        name: 'What’s Allowed in My Pet’s Cage with Virgin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Virgin does not allow any toys or other items inside your pet’s crate. Virgin and Dogtainers take no responsibility for any lost items during transport. Any items you send are at your own risk.   A firmly fixed water bowl that your pet can drink from with a funnel on the outside is mandatory for your pet to fly.  You can place absorbent material in your pet’s crate, such as Deluxe Vet Bedding or a puppy training pad, but no straw, kitty litter or newspapers are allowed. If the material is soiled during the flight, it will not be returned after the flight.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Can’t I Put in My Pet’s Crate with Virgin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Do not put any food, leads, or harnesses in your pet’s crate, as these are not allowed by Virgin.',
        },
      },
    ],
  },
  airlines: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Crates Are Permitted For Pet Air Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Only IATA-specified crates are airline approved. All Dogtainers crates are IATA-specified, which means they’re accepted by all airlines. We have a range of crate sizes available for hire and for sale.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Animals Do Airlines Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Airlines can transport pets and non-pet animals. Pets include dogs (not racing greyhounds), cats, rabbits, guinea pigs, domestic fish with no aeration requirements that aren’t intended for resale, and domestic birds that don’t need a permit to travel (maximum four birds per cage).   Animals that aren’t pets include, but are not limited to: reptiles, crocodiles, race horses, zoo creatures, racing pigeons, mice, day old chicks, live coral/rocks, marine animals for aquariums and research purposes, and racing greyhounds.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do All Airline Flights Take Animals?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. Whether an airplane takes animals as cargo depends on the size of the plane. Smaller plans can’t take animals, or can only take one per flight. Bigger planes can carry more pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Be Booked On The Same Fight As My Animal?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. There are many more seats for people than there are for pets on an airplane. Because of this, if you want to be on the same flight as your pet, it is best to book their flight first. This does not guarantee you will be on the same flight, but it does give you much better odds.',
        },
      },
      {
        '@type': 'Question',
        name: "Do I Get Reward Points For My Pet's Flight?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Virgin’s Velocity Frequent Flyer members can earn 300 Points each time their dog or cat flies on Virgin Australia’s expansive domestic network. Silver, Gold and Platinum members will also receive a special Points bonus.  Qantas does not have a rewards program for pets.',
        },
      },
      {
        '@type': 'Question',
        name: "What happens before and after a pet's flight?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Once lodged at the airline terminal for the flight the airline staff place your pet in a special waiting area. Your pet will be last to be loaded onto the flight to avoid any lengthy waits in the elements on the tarmac.   On the flight the pets are secured into position in the temperature controlled, pressurised area. Unfortunately, as no people are allowed in the plane’s cargo area, pets cannot be checked on while in transit.  Pets are the first off the plane and are taken to the cargo terminal. If this is a layover before their final destination, Dogtainers staff will provide a comfort stop for your pet if there’s time before their next flight. Airlines will confirm your pet is travelling well and ready for the next leg.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Get Updates On My Pet’s Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your pet is flying on an international flight, you’ll receive updates from the airline with pictures at the start and end of the journey. As there’s no people in the cargo area, you cannot get updates on your pet during their flight, unless there’s a layover.   If your pet is flying on a domestic flight, the airline will not update you on your pet’s journey. However, if you elect to have Dogtainers’ drop off or pick up your pet at the airport, we will provide you with updates on how your pet is doing.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Fly My Brachy Breed Dog?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, but only under the guidance of a pet transportation specialist, like Dogtainers. There are certain conditions brachy breeds need to follow to fly that don’t apply to non-brachy breeds.',
        },
      },
      {
        '@type': 'Question',
        name: 'Where Do I Pick Up and Drop Off My Pet at the Airport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Airline pickup and drop off zones vary by airport, but they are typically in the cargo or freight terminal area. Once we’ve confirmed your flights, we’ll let you know exactly where your pet needs to be picked up and dropped off, or can even handle it for you with our Door-to-Door service.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can Big Dogs Fly in Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. Both Virgin and Qantas have a maximum weight of 65kg per pet, including their crate. REX’s weight limit is 30kg.   If your pet + their crate weighs more than 65kg, don’t worry. We can transport your pet on the road in one of our specialty pet transportation vans.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can Brachycephalic Dog Breeds Fly in Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Brachycephalic breeds, also known as “brachy” breeds, “snub-nosed” breeds, or “flat-faced” breeds, can fly in Australia, but only under certain conditions.   Brachycephalic breeds have a significantly higher risk of health complications due to their short snouts and compromised respiratory systems. Breathing difficulties worsen in warmer conditions, when pets are anxious, or when unacclimated to their transport crate. Because of this, they have more conditions for flying than non-brachy breeds.   Brachy breeds can only fly under the guidance of a pet transport specialist, like Dogtainers. We will manage your booking and advise you of all the relevant conditions and documentation so your pet can fly.',
        },
      },
    ],
  },
  qantas: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Will I Need a Permit For My Pet with Qantas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. Different states and countries have varying requirements for pet transport, including the need for a permit. Unfortunately, the airlines won’t tell you which ones apply to you. Luckily, we will! As part of our service, we ensure you’re informed about any and all vaccines, permits, and other requirements your pet must meet in order to travel to their destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'Does Qantas Provide Comfort Stops for Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. While they’re at the airport, your pet will remain in the crate, which is zip tied to ensure their gate remains closed at all times. This is to protect not only your pet, but also the people and other animals near them.   With your permission, one of our professional animal handlers can take your pet back to a local Dogtainers office for a well-deserved rest stop. At our offices, your pet can stretch their legs, use the bathroom, and have a nap away from the ruckus of the airport. For longer stays, we can even take your pet to your preferred kennel or cattery to properly decompress, have a meal, and be groomed. We transport all animals in our customised, climate-controlled, pet-friendly vans, driven by our fully-certified drivers.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can Brachycephalic (snub-nosed / flat-faced) Breeds Travel with Qantas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Brachycephalic breeds, such as pugs, are required to be transported by a pet transport specialist, like Dogtainers. We are a Qantas-approved Brachycephalic transport service, and can advise you on all the requirements brachy breeds need to meet.   To ensure brachy pets are fit for flight, we can organise a Brachycephalic Obstructive Airway Syndrome (BOAS) test with one of our qualified vets, or your preferred reputable vet. Mixed breeds aren’t required to take a BOAS test.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is it Like for Pets to Travel in Qantas’ Pet Cargo?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets travel in a pressurised, temperature-controlled, dimly lit compartment which is usually at the rear of the plane. In this section, travel crates are safely strapped into position by trained airline staff. The captain of the aircraft sets the temperature to around 18 degrees Celsius. With the gentle hum of the aircraft, pets often spend the time dozing before reaching their destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Animals Does Qantas Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Qantas can transport pets and non-pet animals. Pets include dogs (not racing greyhounds), cats, rabbits, guinea pigs, domestic fish with no aeration requirements that aren’t intended for resale, and domestic birds that don’t need a permit to travel (maximum four birds per cage).   Animals that aren’t pets include, but are not limited to: reptiles, crocodiles, race horses, zoo creatures, racing pigeons, mice, day old chicks, live coral/rocks, marine animals for aquariums and research purposes, and racing greyhounds.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I be Booked on the Same Flight as My Animal?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. There are only minimal spots on a flight for pets, whereas there are hundreds of flight seats for people. For the best chance of being on the same flight as your pet, it’s best to book your pet’s flight first. However, this does not guarantee you’ll be together, it just gives you better odds.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens if My Pet’s Flight is Delayed, or Cancelled?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Delayed - The airline must notify us if the delay is a few hours long. Our team then goes to the airport to check on the animal and ensure they’re doing well.  Cancelled - If a Qantas domestic flight is cancelled, Qantas automatically offers the next available spot. Our team will contact you to confirm if you’d like to proceed. Depending on what you want to do, we can go pick up your pet and bring them back to our office, to boarding, or back to your residence.',
        },
      },
      {
        '@type': 'Question',
        name: "Do I Get Reward Points For My Pet's Qantas Flight?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, Qantas does not offer reward points for pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens When My Pet’s Flight Lands?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'For safety reasons, animals are first to be loaded off the aircraft. Pets are then brought to the Qantas Freight Terminal and are usually ready for collection around 45 to 90 minutes after flight arrival. For regional ports, please collect your pet 10 - 30 minutes after the inbound flight has departed. Owners need to have their ID and airway bill (digital or printed) to collect the pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Get Notified About My Pet’s Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Unfortunately, airport staff only send updates for international flights. If you’d like to track your pet’s domestic flight, you can Google their flight number, or check on their Qantas flight status. If you’ve elected to have Dogtainers drop off or pick up your pet from the airport, we can send you updates personally, so you know how your fur baby is doing.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Many Pet Flights Were Booked with Qantas Last Year?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Dogtainers and Qantas have a strong relationship, and in 2021, we arranged Qantas flights for more than 5,500 pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Fly Anywhere With My Pet with Qantas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets on domestic services can only be booked and travel on flights operated by Qantas with flight numbers QF400 and above. This covers most flights going to major Australian cities and regional ports.   If you want to fly internationally, there are some countries that Qantas is not currently flying to. This includes pet and animal bookings between Australia and London, Australia and Vancouver, and from Hong Kong to Australia. If you’d like a complete list of countries Qantas doesn’t fly to, feel free to call us on 1300 13 52 52, or fill in our obligation-free quote and we’ll get back to you.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Pack Medications or Special Food for My Pet on a Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Airline regulations state that we cannot send food with your pet when they travel. We can send medications with your pets if they are identified by the vets and clearly state the content. However, the airline reserves the right to refuse the medication.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Need to Measure My Pet for Their Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Be sure to weigh and measure your pet correctly. If at the time of lodgement there are any weight discrepancies, or a larger crate is required, your pet may be offloaded, and additional charges will be incurred. Learn more about crates and measuring your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'Should I Feed My Pet Before They Fly?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We recommend not feeding your pet within 8 hours of travel as this can cause them to have an accident inside the crate. Although it is natural for your pet to answer nature’s call, if your pet has an accident in their crate before their flight, the airline will refuse to put them on the flight until a Dogtainers representative can clean the pet and inside the crate. This can then result in flights being missed, and additional charges may apply.',
        },
      },
      {
        '@type': 'Question',
        name: 'What’s Allowed in My Pet’s Cage with Qantas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'A firmly fixed water bowl that your pet can drink from with a funnel on the outside is mandatory for your pet to fly.  You can place absorbent material in your pet’s crate, such as deluxe vet bedding or a puppy training pad, but no straw, kitty litter or newspapers are allowed. If the material is soiled during the flight, it will not be returned after the flight.  Qantas lets you add one small toy to your pet’s crate. However, Qantas and Dogtainers take no responsibility for any lost items during transport. Any items you send are at your own risk.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Can’t I Put in My Pet’s Crate with Qantas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Do not put any food, leads, or harnesses in your pet’s crate, as these are not allowed by Qantas.',
        },
      },
    ],
  },
  ipata: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What is an IPATA Agent?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'IPATA is an international network of pet relocation specialists working together to provide professional pet transportation services worldwide. An IPATA agent is a member of this network. IPATA members are required to maintain current licences, permits, and insurance at all times, as well as follow the IPATA ethical guidelines for relocating animals.',
        },
      },
      {
        '@type': 'Question',
        name: 'Is Dogtainers an IPATA-Regulated Pet Shipping Agent?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes! We’ve been an IPATA member for years and are currently a featured Gold member.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Does IPATA Stand For?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'IPATA stands for the “International Pet And Animal Transportation Association”.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does an IPATA Membership Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The IPATA Application Fee is $1000 with an additional annual fee of $980 for ongoing membership.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is BOAS Assessment?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Dogs with a flat, wide-shaped head are said to be brachycephalic. BOAS stands for Brachycephalic Obstructive Airway Syndrome. BOAS is the condition where the soft tissue in the nose and throat of some brachy breeds may partially obstruct the airway and make it difficult for them to breathe normally.   IPATA has developed the “BOAS Fit-To-Fly assessment checklist” which guides certified veterinarians through the process of objectively assessing a pet’s overall health, specifically grading their breathing ability. Pets that pass the checklist are considered “fit to fly”.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are IPATA’s Dog Shipping Rates?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Costs are determined by the size and weight of your dog, the city of origin and destination, plus any additional services that you request, such as pickup and delivery, quarantine arrangements (if required), veterinary health certificates, and import licences. If you’d like, you can get a free online quote to get a better idea of how much shipping your dog will cost.',
        },
      },
      {
        '@type': 'Question',
        name: 'Does IPATA Approve Vets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, IPATA does not approve vets - governments do. The vast majority of countries require a “government approved vet” to approve a pet prior to travel. These veterinarians have been accredited by the government of the exporting country for the preparation of cats and dogs for export. This includes scanning for microchips, clinical inspections, collection of blood samples, and completing treatments.   If you already have a government approved vet who you like, we are happy to work with them to ensure your pet’s transportation goes smoothly. Otherwise, we have a network of government approved vets across Australia and the world who can help ensure your pet is travel ready.',
        },
      },
    ],
  },
  domesticRoadPetTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pets For A Long Car Trip?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'For long car trips, best to ensure your pet has a positive association and experience with being inside vehicles. To minimise stress make it regular that you reward your pet for being in a car. Ensure the environment puts them in a comfortable state of mind and not stressed or anxious',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Best Method To Transport Pets By Road?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The best method would be utilising a company that invests in vans. Some pet transport companies utilise dog trailers to transport dogs, but these can be unsafe due to them being attached to the back of a vehicle. It is a much safer method to use a company that transports pets via vans as they are more structurally secure, allow for better air ventilation, and add to the safety of pets also being secured within transport carriers.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Safety Measures To Transport A Pet By Road?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The safest measures we implement during road transport are each animal is transported in their own transport carrier (except puppies and kittens), each crate is strapped securely within the back of our van and no crates are placed on top of each other. We gauge based on animal behaviours which pets are more comfortable travelling next to each other. During transport, no pets come into contact with one another. We also strongly advise that all pets are fully vaccinated for transport to ensure not only their safety but other animals.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Types Of Crates Or Carriers Are Suitable For Transporting Pets By Road?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The crate or carrier should be well-ventilated, comfortable, and secure, and regulations regarding pet transportation should be followed.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Temperature Restrictions For Pet Transport In A Vehicle?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It is always strongly advised when transporting animals that they have either cool airflow or are available for air conditioning.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Do I Need For Transporting Pets Across State Borders In Australia By Road?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Some pet types and breeds will require licences and permits to travel interstate. Generally, this only applies to birds, reptiles, exotic animals, and farm animals (not limited). But best to check with state quarantine facilities on the requirements for entry to arrange these documents.',
        },
      },
    ],
  },
  domesticPetTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport Pets Domestically?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'There are a lot of factors that determine the cost of pet transport domestically. With the range of services we offer and the different airlines we utilise and different routes for pet transport, being able to find a cost on how much pet transport is can be difficult. Depending on customer needs they can expect to pay anything upwards of $300, then it’s dependent on the size of the animals, amount of animals, routes, and services customers select to add to their pet transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Domestic Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Best to ensure your pets are as calm as possible in their upcoming transport. Given animals require to be in a crate, it helps if they have prior crate training experience. If customers' pets experience high levels of stress, it's best to try Adaptil Collars or Feliway spray to assist with stress before, during, and after transport. The night before transport it is best to have your pets secured within the house so there are no issues on the day (such as cats disappearing) On the day of transport ensure they’ve had plenty of time to go to the bathroom and have fasted for at least 8 hours prior to travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Book Transport For My Pet Within The Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Best to give us a call to arrange a quote and determine service requirements prior to arranging travel. If customers have an idea of their dates and routes, and if they’re also travelling their target flights. They should call us with this information and we can seek availability for animals due to the limited spacing available for animals on flights.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Transport My Pet By Air Within Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets are transported via commercial passenger airlines. They are lodged to our airline freight departments to be loaded onto the aircraft within the cargo hold. Animals have specific spacing within the cargo hold so there is no mixing between bags. They are secured with straps in the cargo hold against the side and a secure net placed around the transport carriers for stability.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Book Transport For My Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It is always best to book in early to ensure spacing as there is limited room compared to passengers for animals on commercial flights. We can secure flights 3 months in advance but are limited to 14 days prior to departure with Qantas spacing.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Is Required For Domestic Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Documentation will depend on your origin, destination, pet breed, and pet age. It is recommended that for customers' own peace of mind, they get a health check on their pet regardless if it is required based on the previous comments or not.",
        },
      },
    ],
  },
  internationalPetTransportNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Does International Pet Transport Work?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "International pet transport involves a series of steps to ensure the safe and comfortable journey of your pet. It typically includes the arrangement of flights, documentation and paperwork, crate preparation, and adhering to health and safety regulations. Our experienced team will guide you through the process, handle all necessary arrangements, and ensure that your pet's travel is smooth and stress-free.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Are Required For International Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The specific documents required for international pet transport may vary depending on the destination country. However, common documents often include a pet passport or health certificate issued by a veterinarian, proof of vaccinations, microchip details, and import permits or quarantine certificates. Our team will assist you in understanding and obtaining the necessary documentation for your pet's travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Health Requirements For Pets Travelling Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are usually specific health requirements for pets travelling internationally. These requirements can include up-to-date vaccinations, a recent health examination by a veterinarian, and sometimes additional tests or treatments based on the destination country's regulations. It is essential to consult with our team and the relevant authorities to ensure compliance with health requirements.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Breeds Or Types Of Pets That Can Be Transported Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Some countries may have restrictions on certain breeds or types of pets that can be transported internationally. These restrictions could be related to breed-specific regulations, local laws, or import restrictions. Our team can provide guidance on any breed or type-specific restrictions that may apply to your pet's travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens If My Pet Has Special Medical Needs During The Journey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If your pet has special medical needs, it is important to inform our team during the booking process. We will work closely with you to understand your pet's requirements and ensure that appropriate accommodations are made during the journey. This may include special handling, specific medical treatments, or arrangements for a veterinarian to be available at the destination if needed.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do You Handle Customs Clearance For Pets Arriving In A Foreign Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We have extensive experience in handling customs clearance for pets arriving in foreign countries. Our team will assist you in preparing all necessary documentation, such as import permits and customs declarations, to facilitate a smooth clearance process. We work closely with local authorities and customs officials to ensure compliance with all regulations and ensure a seamless transition for your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Approximate Cost Of International Pet Transport From New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of international pet transport from New Zealand can vary depending on several factors, including the destination country, the size and weight of the pet, the airline or pet transport service chosen, and any additional services required. In general, the cost of international pet transport from New Zealand can range from approximately $500 to $5,000 or more. It's important to note that this is a rough estimate, and the actual cost can vary significantly based on various factors specific to your situation. To obtain a more accurate cost estimate, consider contacting a pet transport company that specialises in international pet transportation.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For Pets Returning To New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Due to its rabies-free status, New Zealand has implemented stringent regulations for importing pets. If you plan to bring your pet to New Zealand by air, it must arrive exclusively in Auckland or Christchurch and undergo a brief stay at authorized quarantine facilities before reuniting with their owners. The quarantine regulations change depending on the country your pet is travelling from, and the rabies status of that country.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does The Pet Import Process To New Zealand Take?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Generally, it takes a minimum of 2-3 months and a maximum of 6 months for the entire process. The time duration depends on the country of origin and the type of pet including breed. During this period, pets must undergo a series of tests, treatments, and quarantine periods to ensure they meet the strict health and safety requirements of New Zealand's Ministry for Primary Industries (MPI). It is important to begin the process well in advance of your intended travel dates to avoid delays and ensure that all requirements are met.",
        },
      },
    ],
  },
  internationalPetTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Preparing your pet for international travel involves several steps, including:  Crate training: Familiarise your pet with the crate to help them feel comfortable and secure during transport.  Consult with our experienced travel consultant: Our consultant can provide you with information on the requirements for pet travel to your destination country.  Health check with your vet: Before travel, discuss your pet's health with your veterinarian to ensure they are fit to travel.  Road trips in the crate: Gradually expose your pet to longer periods in their crate, to help them get used to being confined during travel.  Familiar scents: Place a shirt that you have worn for a few days in the crate to provide your pet with familiar scents, which can help reduce stress during travel.  By following these steps, you can help ensure that your pet is well-prepared for the journey ahead and feels comfortable and safe during the trip.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Transport My Pet To Another Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "When transporting your pet to another country, it is important to plan ahead. It is recommended to start making inquiries about the travel arrangements several months before the intended travel date, as different destinations may have different preparation and booking notice times.  To ensure a smooth and stress-free travel experience for your pet, it is advisable to work with our experienced consultants who will guide you through the process and provide you with all the necessary information and requirements for your pet's destination country.  Our consultants will also help you to schedule the necessary preparation and check that all of the required paperwork is in order. It is important to follow their instructions and guidance diligently to ensure that your pet's travels are as safe and comfortable as possible.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Book Transport For My Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Depending on the country's requirements we would require 1-3 months’ notice in most cases. The notice time for booking pet transport can vary depending on the requirements of the country you're travelling to. Some countries will need longer preparation, If you're unsure, you can reach out to our experienced consultants who can provide you with the specific booking notice time you need to give in order to ensure a smooth and stress-free travel experience for your pet.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Book Transport For My Pet Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To arrange for the international transportation of your pet, you can start first by getting a quote from our team either through our website or by requesting a call to discuss your needs and questions. Once you are happy with the quote, you can use the booking form link to complete the booking process. After we receive your booking, one of our dedicated consultants will be in touch with you.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting A Pet Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To ensure smooth and successful international pet transport, it is important to familiarise yourself with the requirements of the country you will be travelling to. Our experienced team is here to assist you and provide a customised solution based on the specific regulations and requirements of your destination country. Contact us to learn more and to get started with your pet's international travel plans.  Some of the basic requirements include:  Obtaining a travel permit  Microchipping and registering your pet Up-to-date vaccinations Health certificate from a veterinarian Blood tests for certain animal species",
        },
      },
      {
        '@type': 'Question',
        name: 'Which Are The Most Pet-Friendly International Airlines?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We only use airlines that are members of the International Air Transport Association (IATA) and follow their strict set of rules for animal transport, known as Live Animals Regulations, to ensure the safe and secure travel of pets (LAR). This ensures that your pet receives the best possible care while travelling.  We work with a few pet-friendly airlines, including:  Qantas Airways Virgin Australia Air Canada  Virgin  Emirates Garuda Indonesia Hawaiian Airlines Latam  Malaysia Airlines  Qatar Airways   These airlines offer pet travel services and comply with the regulations set by the IATA (International Air Transport Association) to ensure the safe and secure transportation of pets. It is important to note that each airline may have different guidelines and restrictions for pet travel, so it is recommended to check directly for specific requirements before booking.',
        },
      },
    ],
  },
  catTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Is The Best Way To Transport A Cat?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Various factors such as the distance of travel, the mode of transportation, and the cat's temperament determine the best way to transport a cat.  For interstate & international travel, air transport is the quickest option, but it requires planning and preparation ahead.  Choose a reputable pet airline, ensure your cat is in a secure and well-ventilated crate, provide food, water, and medication, and talk to your vet beforehand. For local travel, road transport is generally the best option. Use a sturdy and well-ventilated carrier, secure it on the backseat of the car, make sure it's comfortable and familiar to your cat, and provide food and water, along with a litter box.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Safely Transport My Cat In A Car?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting your cat in a car can be safe and stress-free if you take the necessary precautions. Here are some tips to keep in mind:  Use a sturdy, well-ventilated carrier that is the right size for your cat. Secure the carrier on the backseat of the car using a seat belt or a specialised pet car harness. Make sure the carrier is comfortable and familiar to your cat by placing a familiar blanket or toy inside it. Never place the carrier in the trunk or on the front seat, as this can be dangerous for your cat in the event of an accident or airbag deployment. Consider using pheromones or calming supplements to keep your cat relaxed during the journey. Make frequent stops to allow your cat to stretch their legs and use the litter box.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting A Cat On A Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Transporting a cat on a plane requires careful consideration to ensure its safety and well-being during the journey. The requirements for cat transport on a plane can vary depending on the cat's breed and the location of transport, so it's important to do your research before you fly.   Here are some key points to keep in mind:  Generally, cats are required to be fit and healthy in order to travel by plane. However, some countries or states may have additional requirements such as permits, certificates, or veterinary requirements for cats entering or leaving the area. It's important to check the airline's specific requirements for cat transport, as they may have their own rules and regulations that must be followed. In addition to ensuring your cat is healthy and meets any necessary requirements, you'll also need to provide a suitable carrier that meets the airline's size and ventilation requirements. Make sure your cat is comfortable with the carrier prior to travel, and provide them with any necessary food, water, medication, or other supplies during the flight. If you're travelling internationally, make sure to research the specific import and export requirements for cats in the destination country, as these may differ from the requirements in your home country. By taking the time to research and prepare for your cat's travel, you can ensure a safe and comfortable journey for your furry friend.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Keep My Cat Calm During Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If you're planning to transport your cat, it's important to take steps to keep them calm and comfortable during the journey. Here are some tips to help minimise stress and anxiety for your feline friend to ensure a safe and happy trip:   Choose a suitable carrier that is comfortable and safe for your cat. It should be the right size for your cat to move around, stand up, and lie down comfortably, and should have good ventilation and a secure latch. Help your cat become familiar with the carrier well before the transport date. Leave the carrier out in a familiar area with a soft blanket inside, so that your cat can get used to it and even nap inside. You can also place treats, toys, or a familiar blanket or piece of clothing with your scent inside the carrier to create positive associations. Consider using a calming spray such as Feliway to help ease your cat's anxiety. This spray contains synthetic pheromones that mimic the natural pheromones produced by cats, which can help to create a calming environment for your cat. Simply spray it in the carrier for a few minutes before placing your cat inside. Plan ahead to ensure your cat is comfortable during the journey. Provide a comfortable blanket, toys, and familiar items inside the carrier to help keep your cat calm and entertained. If the journey is long, plan for food, water, and litter box breaks. If your cat becomes agitated during transport, talking with a vet is a great solution. They might be able to suggest extra measures or medication to keep your cat calm throughout the trip.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Get My Cat Used To The Carrier Before The Trip?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To get your cat used to the carrier before a trip, it's important to give them enough time to adjust and associate the carrier with a safe and positive space. You can introduce the carrier by placing treats, toys, or comfortable bedding inside and leaving it out in the open for your cat to explore. Encourage your cat to enter the carrier on their own using food or treats as rewards and praise them for going inside. Leave the carrier open without the door, so your cat can get used to being in it and even take naps inside. Gradually build up the time your cat spends in the carrier, starting with short periods and increasing it gradually over time. These steps can help your cat become comfortable and familiar with the carrier, making the transportation experience less stressful for them.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Cat?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of transporting a cat will vary depending on several factors such as the route, breed, weight, and mode of transport. Generally speaking, transporting a cat locally by road within a few hours can cost around $50-$150. For longer trips within the same country, transport by air can cost anywhere from $200 to $500, with additional fees for special handling, crate rental, or veterinary checks. International travel can cost significantly more, with prices ranging from $500 to $2000 or more, depending on the destination and the airline. Some airlines offer pet travel packages that include additional services such as pick-up and delivery, preflight vet checks, and even pet insurance. It's important to note that prices can vary widely based on the specific details of your cat's travel arrangements, so it's best to get a quote to get a better idea of the costs involved.",
        },
      },
    ],
  },
  catTransportGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Transport My Cat in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport your cat within the UK, you can choose to drive them yourself or hire a pet transport service, like Pets Abroad UK, to handle your cat’s transportation for you. Using a cat transport service ensures professional handling, compliance with regulations, and a stress-free journey for your cat. It saves you time and effort, provides secure and comfortable transportation, and offers peace of mind knowing your beloved feline companion is in experienced hands.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are the Requirements for Cat Transport within the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The requirements for cat transport within the UK include microchipping and up-to-date vaccinations. It's essential to comply with these regulations to ensure the safety and well-being of your cat during travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions or Regulations for Transporting Cats in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "There are no specific restrictions on transporting cats within the UK. However, it's advisable to check with your transport service provider for any specific regulations or guidelines that may apply.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Cat by Air in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, flying with a cat within the UK is possible, as many airlines offer pet travel services. However, it’s often cheaper, quicker, and more convenient to drive them. Professional pet transportation companies such as Pets Abroad UK will drive your cat for you to any location in England, Scotland, or Wales.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does Cat Transport Take within the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The duration of cat transport within the UK can vary depending on the distance, mode of transportation, and any necessary stops or transfers. For shorter distances, such as within the same city, it may take a few hours. Longer journeys, or when flying cats, may take a day or more.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are the Costs Associated with Cat Transport in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The costs of cat transport within the UK can vary depending on factors such as the distance, mode of transportation, and additional services required. However, a rough dollar estimate ranges from £90 to £300. It's best to contact a pet transport service provider, like Pets Abroad UK, for a free, no-obligation quote.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specialised Cat Transport Services in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, there are specialised cat transport services available in the UK. These services cater specifically to the needs of cats and kittens, providing a comfortable and stress-free travel experience. Pets Abroad UK have been providing cat transport services for more than 50 years.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Consider When Choosing a Cat Transport Company in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "When choosing a cat transport company in the UK, consider factors such as their experience, reputation, safety protocols, handling techniques, and customer reviews. It's also important to ensure they have the necessary licences and insurance for pet transport. Pets Abroad UK has more than 50 years of experience in cat transport and strictly adheres to all safety protocols in the UK.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Make the Cat Transport Experience Less Stressful for My Cat?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To make the cat transport experience less stressful for your cat, acclimate them to their carrier beforehand, maintain a calm environment during travel, and provide familiar scents and comforting items. Consult with your veterinarian for additional advice on reducing stress during transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Pet-Friendly Accommodations or Facilities Available during Cat Transport in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are pet-friendly accommodations and facilities available in the UK that cater to the needs of travelling cats. These include pet-friendly hotels, boarding facilities, and rest areas where your cat can rest and relax during the journey. At Pets Abroad UK, we can assist in arranging accommodation at pet-friendly locations if required during your cat's transport with us.",
        },
      },
    ],
  },
  petTransportImportServices: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How much does it cost to import pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Depending on the origin of the pet and the quantity being imported, the price of importing pets to Australia can change. There are additional costs to take into account in addition to transportation costs, such as quarantine fees, veterinary fees, and other import requirements. Typically, prices can range from 8K to 20K.',
        },
      },
      {
        '@type': 'Question',
        name: 'What are the steps to import pets in Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "There are many steps to follow that we provide details guidance on, this is in line with the published requirements from DAFF (Department of Agriculture, Fisheries & Forestry)  The steps to import pets into Australia according to DAFF are as:  Check if your pet is eligible to be imported to Australia.  Apply for an import permit from DAFF.  Meet all import conditions and get your pet ready for travel.  Choose a quarantine station to host your pet.  Make travel arrangements for your pet and ensure that all necessary documentation is in order.  On arrival in Australia, the pet needs to be quarantined (minimum of 10 days for dogs and cats).  It's important to note that the process and requirements may vary depending on the type of pet and country of origin, so it's best to contact us for specific guidance.",
        },
      },
      {
        '@type': 'Question',
        name: 'How long does the import process take for a pet to enter Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Depending on the origin point it is good to start planning 1 year ahead and no less than 6 months ahead of travel. Due to a number of requirements to start very early on, and the capacity at quarantine, starting this soon is essential.',
        },
      },
      {
        '@type': 'Question',
        name: 'What type of health certificate is required for a pet to enter Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The Australian import permit is a dual document of permit/health certificate which we work at obtaining for our clients',
        },
      },
      {
        '@type': 'Question',
        name: 'What are the requirements for importing pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Depending on the country of origin and pets being imported, different countries may have different requirements from DAFF for bringing pets into Australia. However, a few standard prerequisites are as follows:  An import permit for the pet  A health certificate issued by an authorized veterinarian in the country of origin.  A rabies vaccination certificate (dogs and cats only).  Compliance with specific import conditions for the pet species.',
        },
      },
      {
        '@type': 'Question',
        name: 'How much is a pet import permit in Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The permit itself is $480 AUD for the first pet and $240 AUD for each additional pet thereafter.',
        },
      },
    ],
  },
  petTravelBreeders: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Can I Safely Transport My Breeding Pets To Their New Destination?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Safe transport of breeding pets requires careful planning and attention to their specific needs. Working with a professional pet transport service like Dogtainers is crucial. They have the expertise to ensure your pets are transported in secure and comfortable travel crates, with temperature control, proper ventilation, and regular monitoring throughout the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Important Considerations When Transporting Breeding Animals?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Important considerations include breed-specific needs, health requirements, travel regulations, and documentation. It's essential to plan ahead, acclimate your pets to their travel crates, and ensure they are in good health before travel. Working with a reputable pet transport service that specialises in handling breeding animals, like Dogtainers, will help address these considerations effectively.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Health Requirements Or Certifications For Transporting Breeding Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. The specific health requirements and certifications for transporting breeding pets may vary depending on the destination and airline regulations. For commercial purposes, you may need to declare the importation of an animal. Typically, health certificates, vaccinations, and microchip identification are required. Consulting with a professional pet transport service like Dogtainers ensures compliance with all necessary health requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Breeding Pets Internationally? What Are The Regulations?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can transport breeding pets internationally. However, international pet transport involves complying with destination country import regulations, including documentation, vaccinations, and quarantine requirements. Working with a pet transport service experienced in international transport, like Dogtainers, ensures compliance with all necessary regulations.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Ensure The Well-Being And Comfort Of My Breeding Pets During Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ensuring the well-being and comfort of your breeding pets during transport involves using quality travel crates, maintaining appropriate temperature control and ventilation, and providing regular monitoring and care. Professional breeder pet shipping services, like Dogtainers, prioritise the safety and comfort of your pets throughout the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On Transporting Pregnant Breeding Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there may be restrictions on transporting pregnant breeding pets, especially during advanced stages of pregnancy. Airlines and regulations may have specific guidelines. It's important to consult with your veterinarian and the pet transport service to determine the best course of action and ensure the safety of both the mother and the unborn litter.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Is Needed For Transporting Breeding Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Documentation requirements vary depending on the destination and airline regulations. Generally, you will need health certificates, vaccination records, microchip information, import permits, and any specific breed-related certifications. Your pet transport service will guide you on the specific documentation needed for your breeding pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Find A Reliable Pet Transport Service That Specialises In Handling Breeding Animals?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Finding a reliable pet transport service that specialises in handling breeding animals involves researching reputable companies with experience in transporting breeding pets. Look for services that prioritise animal welfare, have knowledge of breed-specific needs, and positive customer reviews. Dogtainers is a trusted pet transport service known for their expertise in handling breeding animals.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Temperature Control Measures In Place For Transporting Breeding Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, temperature control measures are crucial for transporting breeding pets. Reputable pet transport services ensure that travel crates are climate-controlled, with appropriate ventilation to maintain a comfortable temperature range. They also take precautions to protect pets from extreme heat or cold during the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Do If My Breeding Pet Requires Special Care Or Accommodations During Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If your breeding pet requires special care or accommodations during transport, it's important to communicate these needs with your pet transport service in advance. They can make arrangements to cater to your pet's specific requirements, such as extra space, specific feeding instructions,or additional monitoring. Providing detailed information about your pet's needs will help ensure a safe and comfortable journey for your breeding pet.",
        },
      },
    ],
  },
  zooAnimalTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'FAQs About Zoo Animal Transport What Are The Challenges Involved In Transporting Zoo Animals?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting zoo animals presents numerous challenges that require careful consideration. Factors such as the size and unique characteristics of different animals must be taken into account. Some animals may require special equipment and facilities for their safety and comfort during transportation. Others may have specific handling requirements due to their behaviour, dietary needs, or health conditions. Transporting zoo animals requires the expertise of a specialised animal transport service provider who understands the intricacies of handling diverse species and can tailor transport solutions accordingly.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Legal Requirements Are Involved In Zoo Animal Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Zoo animal transport involves complying with legal requirements and obtaining the necessary permits and documentation. Different countries and regions have specific regulations governing the import and export of exotic animals. These regulations may include permits for certain species and documentation to verify the legal and ethical sourcing of animals. It is important to work with a transport service provider that understands these regulatory frameworks and ensures full compliance throughout the transport process. They should have a deep understanding of the complex paperwork and permits required for zoo animal transport, navigating the bureaucracy and streamlining the documentation process for clients.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Types Of Zoo Animals Can Be Transported?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Zoo animal transport can involve a wide range of species, including mammals, birds, reptiles, and aquatic creatures. Mammals commonly transported may include primates, big cats, elephants, and various marsupials. Bird species can range from small colourful birds to majestic birds of prey and even flightless birds. Reptiles such as snakes, tortoises, and crocodiles can also be transported. Additionally, aquatic animals like fish, marine mammals, and penguins can be safely transported with the right expertise and equipment.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Regulatory Considerations For Zoo Animal Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Zoo animal transport involves complying with specific regulations to safeguard animal welfare. Different jurisdictions and countries have regulations in place for the import and export of exotic animals. This may include permits, documentation, and quarantine requirements. It is important to work with a transport service provider that has expertise in regulatory compliance and stays up to date with evolving regulations. They should have a thorough understanding of the necessary permits, documentation, and quarantine requirements to ensure seamless and compliant transportation services for zoo animals.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Permits And Documentation Are Needed For Zoo Animal Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting zoo animals often involves obtaining permits and documentation to comply with legal requirements. Different countries and regions have specific regulations governing the import and export of exotic animals. These may include permits for certain species, health certificates, CITES (Convention on International Trade in Endangered Species) permits, and documentation to verify the legal and ethical sourcing of animals. Working with a knowledgeable transport service provider can help navigate the complex paperwork and ensure compliance with all necessary permits and documentation.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Are Zoo Animals Kept Safe During Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ensuring the well-being of zoo animals during transport is a top priority. Professional transport service providers employ various measures to ensure their safety and comfort. This includes using custom crates designed to meet the specific requirements of each animal, climate-controlled transportation to maintain suitable temperature and humidity levels, specialised handling techniques to minimise stress, and providing appropriate food, water, and veterinary care during the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can Zoo Animals Be Transported Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, zoo animals can be transported internationally, but it requires careful planning and compliance with international regulations. Each country may have specific import and export requirements for zoo animals, including permits, health certificates, and quarantine protocols. A reliable transport service provider with experience in international zoo animal transport can help navigate these regulations, obtain the necessary documentation, and ensure a smooth and compliant transport process.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Risks Involved In Zoo Animal Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting zoo animals does carry inherent risks, including stress, injury, and exposure to unfamiliar environments. However, working with an experienced transport service provider can help minimise these risks. They will have trained professionals who understand animal behaviour and appropriate handling techniques. They will also have suitable transport equipment and procedures in place to ensure the safety and well-being of the animals throughout the entire journey.',
        },
      },
      {
        '@type': 'Question',
        name: "Can I Track The Progress Of My Zoo Animal's Transport?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Many transport service providers offer tracking services that allow you to monitor the progress of your zoo animal's transport. This may include real-time updates on the location of the transport vehicle, estimated arrival times, and any significant milestones during the journey. It is advisable to inquire with the transport service provider about their tracking capabilities and whether such services are available for your specific transport.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens If There Is A Medical Emergency During Zoo Animal Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Transport service providers experienced in zoo animal transport are equipped to handle medical emergencies. They will have trained professionals who can provide immediate veterinary care if needed. They will also have contingency plans in place to ensure the animals' well-being, including access to veterinary hospitals or clinics along the transport route. In case of a medical emergency, the transport service provider will take necessary actions to prioritise the health and safety of the animals.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting Zoo Animals Across State Or National Borders?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: ' When transporting live animals, including zoo animals, through Australia, there are specific requirements that must be met, including obtaining the necessary permits and following the proper procedures for inspection, quarantine, and transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Regulations For Transporting Zoo Animals By Air, Sea Or Land? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "For air travel, compliance with the International Air Transport Association's (IATA) Live Animals Regulations (LAR) is required. This involves properly crating the animals in IATA-approved containers, ensuring their comfort and well-being throughout the journey by taking into consideration factors such as travel time, hydration, and nourishment, and providing adequate rest stops as needed. Regardless of the mode of transportation, animal welfare and security should always be the top priority, and species-specific factors will play a role in determining the necessary precautions and regulations for each individual animal.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Insurance Requirements For Transporting Zoo Animals? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The transportation of zoo animals is a complex process that requires compliance with various regulations, standards, and guidelines to ensure the safety and well-being of the animals. Insurance is an important aspect of animal transportation, as it provides coverage in case of any accidents or incidents during transit. This insurance may cover losses or damages to the animals, as well as any related liability. It is important to note that insurance requirements for zoo animal transportation may vary depending on the species, mode of transport, and destination country.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do We Acclimate The Zoo Animals To Transport Crates Before Travel? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Getting zoo animals accustomed to the transport crates prior to travel is a key aspect of preparing them for a comfortable and safe journey. One approach to acclimate them is to provide them with access to the transport crates several days or even weeks before the travel date. This will give them the opportunity to get familiar with their new surroundings and reduce stress during transport. It is important to note that the specific acclimation methods may vary depending on the type of animal being transported.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do We Properly Secure And Handle Zoo Animals During Transport? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To ensure the safety and security of zoo animals during transport, there are specific guidelines that must be followed. These guidelines are in place to ensure the welfare and well-being of the animals while they are being transported. Depending on the species, size and type of animal, the guidelines may vary, but it is essential to always prioritize the comfort and security of the animal during the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Sanitation Requirements For The Transport Of Zoo Animals? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are guidelines for proper sanitation during the transport of zoo animals. The International Air Transport Association's Live Animals Regulations (IATA LAR) must be followed to ensure the proper care and well-being of the animals during transport. This includes ensuring that the transport containers are clean and free from any harmful substances or pathogens and that the animals themselves are maintained in a healthy state throughout the journey. Proper sanitation is crucial for the comfort and safety of zoo animals and is a key consideration for those involved in the transportation of these creatures.",
        },
      },
    ],
  },
  crateHire: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Import To The USA?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To import a pet to the USA, there are a few requirements to be met. Firstly, your pet must have a valid rabies vaccination at least 14 days before import. Additionally, your pet must be microchipped, and the microchip number should be recorded on all documentation related to the pet's travel.   In some cases, additional requirements such as a health certificate or specific vaccination may be required depending on the pet's country of origin and breed. It is always best to check with your airline and the USDA Animal and Plant Health Inspection Service for specific requirements before making travel arrangements for your pet.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Pet To The United States?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of transporting a pet to the United States is determined by a number of factors. Pet transportation to LAX, for example, can range between $3,800 and $5,250, depending on the breed. Other factors such as travel distance, mode of transportation, pet size and weight, and any additional services may affect the cost.  Other major ports and popular pet travel destinations include:  JFK: $3,600 - $5,000  SFO: $3,800 - $5,000  MIA: $3,800 - $5,500  ATL: $3,800 - $5,250  These cost estimates can change based on various factors, to get a tailored quote for more accurate pricing.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Transport My Dog From Australia To The USA?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting your dog from Australia to the USA can be a complex process, but here are some steps you can take to ensure a smooth transition:  Make sure your dog is microchipped and up-to-date with all required vaccinations, including rabies.  Choose an airline that allows pets and has appropriate facilities for their transport.  Select an airline-approved pet crate that is the right size for your dog to ensure their comfort and safety during the journey.  Provide your dog with familiar bedding and toys in a pet carrier to help reduce stress during travel.  Get in touch with our team of pet transport experts, to guide you through the process and ensure that all necessary paperwork and permits are obtained including door-to-door transport or pet accommodations if needed.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process For Getting A Health Certificate For My Pet To Enter The United States?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The process involves obtaining a health certificate from a veterinarian and complying with the destination state's import requirements. The health certificate must be issued within 10 days of travel and should include information on the pet's health status and vaccination records. The import requirements vary by state and may include additional vaccinations, quarantine periods, or specific health tests. It's essential to research and comply with the destination state's requirements to avoid any complications during travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does The Pet Import Process Take In The United States?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Upon arrival, the import process usually takes 3-4 hours. Depending on factors such as the specific airport and the volume of pet imports during the season, the time frame may vary. Make sure you have all of the necessary documentation and requirements fulfilled to avoid any delays. If you need further we can arrange this with our USA agent.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet By Car Or Vehicle Across The United States?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Certainly! Pet owners can transport their pets across the United States by car or other vehicles once their pets have been cleared through customs and quarantine upon arrival. It is essential to ensure that the pets are transported safely and comfortably during the trip. It's a good idea to consult with a veterinarian beforehand and take necessary precautions, such as making sure your pet is properly secured and has access to water and fresh air during the trip. Additionally, some states may have specific regulations or restrictions on transporting pets, so it's important to research these beforehand to avoid any complications.",
        },
      },
    ],
  },
  crateHireGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Why Should I Consider Renting A Pet Crate Instead Of Buying One?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Renting a pet crate offers several advantages. It provides convenience, as you can rent a crate specifically designed for pet transportation without the need for long-term storage. Renting is also cost-effective, especially if you only require a crate for occasional trips. Additionally, renting ensures compliance with transportation regulations, as rental crates are often designed to meet international airline and transportation standards.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Hire A Pet Crate?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of hiring a pet crate can vary depending on factors such as the size of the crate, the rental duration, and the specific rental service provider. Rental fees are generally designed to be affordable, offering a cost-effective alternative to purchasing a crate outright. It's best to contact the pet crate rental service directly for specific pricing information.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Sizes Of Pet Crates Are Available For Hire?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "We offer a comprehensive range of sizes to cater to various breeds and pet sizes. Our selection starts with the 'small' size, which corresponds to the IATA series 100 model, and extends all the way to the 'giant' size, represented by the IATA series 700 model. This ensures that every pet, from the smallest to the largest, can find a suitable and comfortable crate for their travel needs. Common sizes include small, medium, and large crates. The specific sizes available for hire may vary depending on the rental service provider. It's advisable to contact them to inquire about the available sizes and select the appropriate crate for your pet.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are The Pet Crates Clean And Well-Maintained?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, reputable pet crate rental services ensure that their crates are clean and well-maintained. They follow strict hygiene protocols and conduct thorough cleaning and maintenance between rentals. The crates should be in excellent condition, providing a safe and comfortable environment for your pet during their journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Rent A Pet Crate For International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, many pet crate rental services, such as Pets Abroad UK, offer crates suitable for international travel. These crates are typically designed to meet international airline and transportation regulations, ensuring compliance and the safety of your pet during the journey. It's important to confirm with the rental service that their crates meet the specific requirements for your intended international travel destination.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Can I Hire A Pet Crate?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The rental duration for pet crates can vary depending on the rental service provider and your specific needs. Some providers offer flexible rental periods, allowing you to rent a crate for as short as a few days or as long as several weeks. It's best to discuss your requirements with the rental service and arrange a rental duration that suits your travel plans.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportUSA: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Determine The Right Pet Crate Size?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The right pet crate size is determined based on the breed, age, weight, and dimensions of your pet to ensure they have enough space to move and turn around freely. A good rule of thumb is to have at least 5 cm of clearance between the top of your pet's head or ears and the crate's ceiling. Your pet should also be able to lie down comfortably and stand up or turn around without restriction against the pet carrier door.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Regulations For Pet Travel Crates For Flights?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The regulations for pet travel crates can vary depending on the airline and destination. Generally, pet travel crates must be made of sturdy materials, such as two-piece plastic containers held together with metal bolts, one spring-loaded door, and ventilation on the top half of the carrier for proper airflow. In some cases, wooden transport crates may also be allowed. It's important to check with the airline ahead of time to ensure you have the correct crate size and type, and any required documentation.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Make My Pet Carrier More Comfortable During Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The best way to make your pet more comfortable during transport is to ensure they are used to being contained within a carrier.  It's recommended to get your pet familiar with the carrier beforehand, so they feel comfortable being inside it. You can do this by placing familiar toys or treats inside the carrier and leaving the carrier open for them to explore. During travel, you can add a small blanket or t-shirt that smells like home to help calm your pet. Please make sure your pet is well-fed and hydrated before the journey.",
        },
      },
      {
        '@type': 'Question',
        name: 'How To Properly Secure A Pet Crate During Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Securing your pet crate during travel is crucial to ensure your pet's safety. If you're traveling by car, it's recommended to secure the crate safely in the backseat using a seatbelt looped around the carrier. This will prevent the carrier from sliding or shifting during sudden stops or turns. If you're traveling by plane, the airline staff will secure the crate for you.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Sanitation Requirements For Pet Crates?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Use a high-quality disinfectant, such as F10 Disinfectant, to clean the crate thoroughly. This will help eliminate any harmful bacteria or viruses that may be present, ensuring a safe and healthy sanitised environment for your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Acclimate My Pet To The Crate Before Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Acclimating your pet to the crate is essential to help them feel comfortable during travel. Start by choosing the right size crate and introducing your pet to it gradually. Allow them to explore the pet carrier on their own, and reward them with treats and positive reinforcement when they do so. You can also feed your pet inside the carrier to help them associate it with positive experiences. With patience and consistency, your pet should feel comfortable and relaxed inside the crate, making travel less stressful for them.',
        },
      },
    ],
  },
  petsFromQuarantine: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does Pet Quarantine Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of pet quarantine in Australia can range from around $1800 to $2500 for one pet, depending on the duration of the quarantine period required. The quarantine fee covers the cost of accommodation, food, and the necessary medical treatments that the pet may require during its stay.',
        },
      },
      {
        '@type': 'Question',
        name: 'Where Do Pets Quarantine In Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Post-entry quarantine services for pets entering Australia are located in Mickleham, Victoria. The facility is managed by the Department of Agriculture, Water and the Environment and is equipped with state-of-the-art facilities to ensure that pets are kept in a safe and secure environment during their quarantine period.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Is The Quarantine Period For Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The quarantine period for pets in Australia ranges from 10 to 30 days, depending on the type of pet and the preparations completed before departure. The preparations include vaccinations, blood tests, and treatments, such as tick and flea treatments, deworming, and other medical procedures.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Prepare For My Pet’s Quarantine In Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Pet owners should ensure that their pets have received all the necessary vaccinations, blood tests, and treatments before departing for Australia. The requirements for each type of pet and place of origin can vary, so it is important to check the Department of Agriculture, Water and the Environment's website for the latest information.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Kind Of Care And Attention Will My Pet Receive During Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "During their quarantine period, pets will be cared for by highly experienced staff who are trained to meet all the pets' behavioural and health needs. The quarantine facility is equipped with spacious and clean facilities to ensure that pets are comfortable during their stay.",
        },
      },
      {
        '@type': 'Question',
        name: "How Can I Stay Updated On My Pet's Status During Quarantine?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Pet owners can contact the quarantine facility via email or phone on weekdays to receive updates on their pet's status during quarantine. The facility staff will provide regular updates on the pet's health and well-being, as well as information on the release process once the quarantine period is over.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportCanada: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: "What's The Cost Of Moving A Pet To Canada?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of moving a pet to Canada typically ranges from $3,800.00 to $4,900.00. The cost may vary depending on factors such as the mode of transportation and the size of the pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'How To Transport My Dog From Australia To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To transport your dog from Australia to Canada, you will need to follow a few essential steps. These include obtaining an airline-approved pet travel crate, ensuring that your pet has been microchipped, scheduling a final vet inspection with an Australian registered vet, making an appointment with the Department of Agriculture, Water and the Environment for an export permit and health certificate, and contacting the airline to book your pet's flight. By completing these steps, you can ensure a safe and comfortable journey for your furry companion.",
        },
      },
      {
        '@type': 'Question',
        name: "What's The Process For Transporting My Pet To Canada When I'm Moving Permanently?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When moving permanently to Canada with your pet, the process is slightly different. You will need to ensure that your pet meets all Canadian import requirements, which may include a health certificate, a rabies vaccination certificate, and other documents. You will also need to make arrangements to clear your pet through Canadian customs upon arrival.',
        },
      },
      {
        '@type': 'Question',
        name: 'Any Size Or Weight Restrictions For Pets During Transport To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Generally, there are no weight restrictions for pets during transport to Canada. However, there may be height restrictions for the airline you choose, and it is recommended that you contact the airline to confirm any restrictions before booking your pet's flight.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need To Bring A Dog Into Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your dog is under 8 months of age, it will require a C3 or F3 vaccination and an import permit from the Canadian Food Inspection Agency. Other documents that may be required include a health certificate, proof of rabies vaccination, and any other relevant medical records.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Specific Regulations For Transporting Service Or Emotional Support Pets To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Some airlines will accept service or emotional support pets that have been certified. It is important to note that different rules may apply for service animals versus emotional support animals, and it is essential to comply with all relevant regulations when travelling with your pet.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportUnitedKingdom: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Transport My Pet From Australia To The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport your pet from Australia to the UK, there are several steps that need to be taken. First, you must obtain an airline-approved pet carrier and ensure that your pet has been microchipped. Then, your pet must receive a rabies vaccination more than 21 days prior to travel and undergo a final vet inspection by an Australian registered vet. You must also make an appointment with the Department of Agriculture, Water and the Environment for an export permit and health certificate. After that, you need to apply for a UK Transfer of Residency (TOR) and contact the airline to make a flight booking.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Pet In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of transporting a pet to the UK can range from $3,800.00 to $5,400.00. This cost includes the transportation fee, health certificate, and other associated costs.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring My Pet Into The UK Without Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can bring your pet into the UK without quarantine if you follow all the necessary procedures. Clearance usually takes between 4 to 5 hours, after which you can take your pet home.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Vaccinations When Travelling To The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets travelling to the UK must have a rabies vaccination more than 21 days prior to travel. The vaccination must be given by a registered veterinarian and must be up to date.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Obtain A Pet Passport For Travelling To The UK With My Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Once you arrive at a UK port with your pet, UK customs and quarantine will complete the necessary paperwork and issue a pet passport.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Regulations For Transporting Exotic Pets To The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If you are transporting an exotic pet to the UK, you will need to obtain an import permit from UK quarantine. Additionally, the pet may require a 4-month quarantine period upon arrival in the UK. It is important to check the UK government's website for the latest regulations regarding the transportation of exotic pets.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportSouthAfricaGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting Pets From The UK To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To transport your pet from the UK to South Africa, you will need to comply with South Africa's import requirements, including obtaining an import permit and providing documentation of vaccinations and a veterinary health certificate. Additionally, you will need to use an IATA-approved crate, and your pet must be microchipped.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Is Required For Pet Transport To South Africa From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport your pet to South Africa from the UK, you will need to provide an import permit, a veterinary health certificate, and proof of vaccination for your pet. These documents must be obtained from your veterinarian and presented upon arrival in South Africa.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring My Pet On A Direct Flight To South Africa From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, some airlines offer direct flights from the UK to South Africa that allow pets. However, it is essential to check with the airline for their pet travel policies and requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Cost Of Pet Transport From The UK To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of pet transport from the UK to South Africa can vary depending on several factors, such as the airline, the size of your pet, and the route taken. It is best to contact pet transport companies or airlines directly for quotes.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Breeds Of Pets That Can Be Transported To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, there are no restrictions on the breeds of pets that can be transported to South Africa. However, some airlines may have specific breed restrictions, so it is best to check with them directly.',
        },
      },
      {
        '@type': 'Question',
        name: 'Is It Safe To Transport Pets By Air To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, transporting pets by air to South Africa can be safe, provided that proper precautions and safety measures are taken. It is important to choose an airline with a good reputation for pet transport, use an IATA-approved crate, and follow all necessary regulations and guidelines.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Travel With My Pet In-Cabin Or Do They Need To Travel As Cargo?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Most airlines require pets to travel as cargo rather than in-cabin, with the exception of service animals. It is best to check with the airline for their specific policies and requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For Pets Arriving In South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, there are quarantine requirements for pets arriving in South Africa. Pets will need to undergo quarantine for a period of at least ten days upon arrival in South Africa.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Size Crate Is Required For My Pet During Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The crate size required for your pet during transport to South Africa will depend on their size and weight. It is essential to choose a crate that allows your pet to stand, turn around, and lie down comfortably. The crate must also be IATA-approved.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Allow Pets To Travel To South Africa From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Several airlines allow pets to travel to South Africa from the UK, including British Airways, Emirates, Qatar Airways, and South African Airways. However, it is essential to check with the airline for their pet travel policies and requirements.',
        },
      },
      {
        '@type': 'Question',
        name: "Can I Track My Pet's Journey During Transport To South Africa?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, some pet transport companies and airlines offer tracking services to allow pet owners to monitor their pet's journey during transport to South Africa.",
        },
      },
      {
        '@type': 'Question',
        name: 'What If My Pet Has A Medical Condition, Can They Still Be Transported To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, pets with medical conditions can still be transported to South Africa, provided that they are fit to travel. It is important to notify the airline or pet transport company of any medical conditions before the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Age Restrictions For Pets Travelling To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'There are no age restrictions for pets travelling to South Africa. However, some airlines may have specific age restrictions, so it is best to check with them directly.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet By Road From The UK To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting a pet by road from the UK to South Africa is not practical due to the significant distance and the requirement to pass through several countries with varying regulations. The most common way to transport a pet to South Africa from the UK is by air.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Do I Need To Book Pet Transport To South Africa From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It is recommended to book pet transport to South Africa at least 2-3 months in advance to ensure availability and to allow sufficient time for all necessary preparations and documentation to be completed. Some airlines may require even more advanced notice, so it is best to check with the airline directly for their specific requirements.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportSouthAfricaNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does Pet Transport To South Africa Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet transport costs to South Africa can vary depending on factors such as the size of the pet, the distance of travel, the airline chosen, and additional services required. However, a rough estimate ranges from $4,000 - $6,000 NZD. Dogtainers can provide you with a personalised quote based on your specific needs.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport your pet to South Africa, you will need to comply with certain requirements, depending on the country your pet is coming from. This typically includes a valid health certificate, vaccinations, blood tests, a microchip, and compliance with specific import regulations. Dogtainers can guide you through the detailed requirements to ensure a smooth and successful journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does Pet Transport To South Africa Take?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The duration of pet transport to South Africa varies depending on factors such as the origin and destination locations, flight availability, and any layovers. From New Zealand, your pet will be in flight upwards of 17 hours, but it may take several days including layovers. Dogtainers will provide you with a timeline based on your specific travel arrangements.',
        },
      },
      {
        '@type': 'Question',
        name: 'Is It Safe To Transport Pets By Air To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, transporting pets by air to South Africa can be safe when proper precautions are taken. Airlines have specific protocols in place to ensure the safety and well-being of pets during travel. Dogtainers works with reputable airlines that prioritise the comfort and safety of pets, providing temperature-controlled environments and trained staff.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Quarantine Requirements For Pet Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, there are no restrictions on the breeds of pets that can be transported to South Africa. However, some airlines may have specific breed restrictions, so it is best to check with them directly.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Offer Pet Transport Services To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Several airlines offer pet transport services to South Africa, including Air New Zealand, Qatar Airways, Qantas, and Emirates. The availability of pet transport services may vary depending on the airline's policies and the specific route. Dogtainers can assist you in selecting an airline that suits your needs and meets the required standards for pet transport.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Accompany My Pet During The Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. You cannot accompany your pet during transport to South Africa. However, you can travel on the same plane. Pets must be transported in the cargo hold of the aircraft, which is specially designed to provide a safe and comfortable environment for them. Dogtainers can provide guidance on the specific regulations for accompanying your pet during transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Are Needed For Pet Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The required documents for pet transport to South Africa typically include a valid health certificate issued by a veterinarian, proof of up-to-date vaccinations, a microchip certificate, and an import permit. Additional documents may be required depending on the specific circumstances. Dogtainers will provide you with a comprehensive list of required documents and assist you in ensuring they are completed accurately.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Should I Prepare My Pet For Travel To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Preparing pets for travel to South Africa involves several steps. It includes acclimating them to their travel crate, ensuring they are in good health with up-to-date vaccinations, and providing comfort items such as familiar bedding or toys. Dogtainers will provide you with detailed guidelines on how to prepare your pet for travel to South Africa, ensuring their comfort and well-being throughout the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Breed Restrictions For Pet Transport To South Africa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'South Africa does not have specific breed restrictions for pet transport. However, it is important to check the regulations of your airline and the specific requirements of your destination to ensure compliance. Dogtainers can provide guidance on any breed-specific considerations and help you navigate any potential restrictions or requirements for transporting your pet to South Africa.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportEurope: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Is The Cost Of Transporting My Pet To Europe From Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of transporting pets to Europe from Australia ranges between $4,400.00 to $5,825.00. This cost may vary depending on factors such as the airline, route, size of the pet, and the specific requirements for transport. It is important to obtain a quote from a reputable pet transport company that includes all necessary expenses such as veterinary checks, crate rental, and customs fees.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Specific Requirements For Transporting Pets Within The European Union?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When transporting pets within the European Union, specific requirements must be met. Your pet must obtain an EU passport from an authorized veterinary, have a valid rabies vaccination, and note that the maximum number of pets allowed is five. Additionally, depending on the country you are travelling to, there may be additional requirements such as microchipping, blood tests, or specific health certificates. It is essential to check the specific requirements of the country you are travelling to with a reputable pet transport company.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Recommended Airline For Transporting Pets To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Emirates, Qatar, and Singapore Air are recommended for transporting pets. These airlines have a good reputation for pet transport and provide suitable conditions for pets during transit. However, it is important to note that the airline may vary depending on the specific requirements of your pet and the country you are travelling to.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Kind Of Crate Is Suitable For My Pet During Transport To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Crates must be IATA and airline-approved. These crates meet strict international standards and are designed to provide a safe and comfortable environment for pets during transit. It is important to ensure that the crate is the correct size for your pet and that they are comfortable and familiar with it before the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'How much time is required for preparation before pet transport to Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "We ask clients for one month's notice to ensure everything is properly prepared. This timeframe allows for sufficient time to meet all necessary requirements for pet transport, including veterinary checks, vaccinations, and obtaining the required paperwork. It also allows time for the pet to become familiar with the crate and any necessary travel arrangements to be made.",
        },
      },
      {
        '@type': 'Question',
        name: 'What is the quarantine process for pets travelling to Europe from Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The quarantine process for pets travelling to Europe from Australia is relatively straightforward. The paperwork is checked on arrival by the relevant authority, and this takes 3-4 hours. Once the paperwork is approved, pets are released to the owner. It is important to ensure that all necessary paperwork is in order before travel to avoid any delays in the process.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportEuropeNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Can I Transport My Pet From New Zealand to Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport your pet from New Zealand to Europe, it is recommended to engage the services of a professional pet transport company. They have the expertise and knowledge to handle the logistics of transporting pets internationally. They can assist with arranging flights, documentation, customs requirements, and ensuring the comfort and safety of your pet throughout the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'WWhat Are The Requirements For Pet Transport To Europe From New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Generally, you will need to ensure that your pet is microchipped, has a valid rabies vaccination, undergoes a blood test to demonstrate sufficient levels of rabies antibodies, and has a health certificate. It is important to check the specific entry requirements of the destination country and comply with any additional regulations they may have.',
        },
      },
      {
        '@type': 'Question',
        name: 'Is There A Quarantine Period For Pets Travelling To Europe From New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets that meet all of the EU’s import requirements do not need to quarantine.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Documents Or Permits Needed For Pet Transport To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. If your pet is coming from inside the EU, they will need an EU Pet Passport. If they are travelling from outside the EU, they will need an EU Health Certificate.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Cost Of Pet Transport From New Zealand To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In general, the cost of travelling from New Zealand to a country in the European Union ranges from $2,200 to $4,400 NZD or more. However, this varies based on the weight of your pet, the destination country, the flight, time of year, and any additional services required. It is recommended to obtain a quote from a pet transport company to get a better idea of the costs involved for your specific circumstances.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does It Take To Transport A Pet From New Zealand To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The duration of pet transport from New Zealand to Europe will depend on factors such as the destination, the chosen route, and any layovers involved. Typically, the transportation duration can exceed 22 hours, but it is more common for the journey to span over multiple days due to transfers and layovers. When utilising the services of a pet transport provider, they will take care of the logistical arrangements to ensure a smooth and well-coordinated journey for your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet By Air To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, air transport is commonly used to transport pets from New Zealand to Europe. Most airlines have specific guidelines and regulations for pet travel, including restrictions on the size and breed of pets that can be transported. It is important to check with the airline regarding their pet transport policies and any specific requirements they may have.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Crate Requirements For Pet Transport To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The crate requirements for pet transport to Europe follow the guidelines set by the International Air Transport Association (IATA). The crate should be well-ventilated, secure, and provide enough space for the pet to stand, turn around, and lie down comfortably. It should also have absorbent bedding and secure fastenings. It is crucial to adhere to the specific crate requirements of the airline or pet transport service you are using.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Types Of Pets That Can Be Transported To Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, Cyprus, Denmark, Germany, Iceland, Lithuania, Malta, Norway, and Switzerland all have prohibited dog breeds. These dogs are typically considered ‘dangerous dogs’ or ‘fighting dogs’.',
        },
      },
      {
        '@type': 'Question',
        name: 'How can I ensure the safety and well-being of my pet during the journey to Europe?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Choose a reputable pet transport service with experience in international travel. Visit the veterinarian beforehand for a health check-up and necessary documentation. Use an airline-approved travel crate and provide identification with your contact information. Keep your pet hydrated. Include familiar items for comfort if allowed and consider temperature regulation. Minimise stress and anxiety with calming aids, but avoid sedatives without professional guidance. Stay informed and updated throughout the journey by maintaining contact with the transport service. By following these guidelines, you can help ensure your pet's comfort and safety during their journey to Europe.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportQatarGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Pet To Qatar From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of transporting a pet from the UK to Qatar can range from a few hundred pounds to several thousand pounds, depending on your specific circumstances. We offer free, no-obligation quotes tailored specifically to your needs and requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Import And Export To Qatar From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The requirements for pet import to Qatar from the UK may vary depending on the type of animal you are transporting. In general, there are some basic requirements that must be met: Pet microchip: Your pet must be microchipped with an ISO-compliant microchip. Vaccinations: Your pet must be up-to-date on its vaccinations, including rabies vaccination. The vaccination must have been given at least 21 days before travel. Blood tests: For dogs and cats, a blood test to check for rabies antibodies must be done at least 30 days after the last rabies vaccination and no more than 3 months before travel. Health certificate: A health certificate must be issued within 10 days of travel. Import permit: You will need to obtain an import permit from the Ministry of Public Health in Qatar.  It is important to note that these requirements may change, so it is always recommended to check with the relevant authorities and your pet transportation provider for the latest regulations and guidelines.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Vaccinations Does My Pet Need To Travel To Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To travel to Qatar, pets require a specific set of vaccinations that must be up-to-date. Mandatory vaccinations may vary depending on the age, species, and health status of the animal, as well as the country of origin. However, in general, the following vaccinations are typically required for pet travel to Qatar: Rabies vaccination: All dogs and cats must have a current rabies vaccination. The vaccination must have been given at least 21 days before travel. Distemper vaccination: Dogs must be vaccinated against distemper, which is a highly contagious viral disease. Feline viral rhinotracheitis, calicivirus, and panleukopenia (FVRCP) vaccine: Cats must be vaccinated against FVRCP, which protects against three common feline diseases. Other vaccinations: Depending on the age and health status of the pet, other vaccinations may be required or recommended by the authorities and your veterinarian.  It is important to note that some vaccinations may require multiple doses or boosters, so it is essential to start the vaccination process early enough before the travel date to ensure that all requirements are met.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does The Pet Transport Process Take?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To ensure a smooth and hassle-free pet transport process, it is recommended to start well in advance as it can take several months to complete. This allows sufficient time for any unexpected delays or complications that may arise during the transport process. To avoid any disappointments, we highly recommend getting in touch with us at least one month prior to your desired date for your pet's arrival in Qatar.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet To Qatar During The Summer Months?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Transporting pets to Qatar during the summer months can be challenging due to the high temperatures and humidity levels. In general, the summer months in Qatar (June to September) are characterized by scorching heat, with temperatures often exceeding 40°C (104°F) and high humidity levels.  Many airlines have restrictions on transporting pets during the summer months, particularly for brachycephalic (short-nosed) breeds, which are more susceptible to heat stroke and breathing difficulties.  If you must travel with your pet during the summer months, it is essential to take necessary precautions to ensure your pet's safety and comfort. This may include travelling during cooler hours of the day, providing plenty of water, and using cooling mats or vests to help regulate body temperature.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For Pets Arriving In Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. There is no quarantine in Qatar, but all animals are placed in a special facility at the Doha International Airport before clearing customs.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Offer Pet Transport Services To Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Several airlines offer pet transport services to Qatar. Here are some of the airlines that you can consider: Qatar Airways: Qatar Airways offers a pet transport service called QR Live Animals. They allow both cats and dogs to travel in the cabin or in the cargo hold, depending on the size of the pet. Emirates: Emirates allows pets to travel as checked baggage or as cargo.  Etihad Airways: Etihad Airways allows pets to travel in the cabin or as checked baggage, depending on the size of the pet.  Turkish Airlines: Turkish Airlines allows pets to travel in the cabin or as checked baggage.  Lufthansa: Lufthansa allows pets to travel in the cabin or as checked baggage.   It's important to note that each airline has specific requirements for pet travel, such as size and weight restrictions, and specific documentation that you need to provide.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Types Of Pets That Can Be Transported To Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The following breeds of dogs are not allowed to enter Qatar: American Staffordshire Terrier. Boerboel. Boston Terrier. Boxer. Bull Terrier. Bulldog. Chinese Shar-Pei. Doberman. Great Dane. Japanese Akita. Neapolitan Mastiff. Afghan Hound. Rottweiler. Staffordshire Bull Terrier.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need To Prepare For Pet Transport To Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To transport your pet to Qatar, you will need to prepare the following documents:  Health Certificate: A health certificate issued by a licensed veterinarian that confirms your pet is healthy and up-to-date on all necessary vaccinations. The certificate should be issued no more than 10 days before travel. Rabies Vaccination Certificate: A certificate that proves your pet has been vaccinated against rabies. The certificate should be issued no less than 30 days before travel. Import Permit: An import permit issued by the Ministry of Environment in Qatar. You will need to apply for this permit at least 30 days before travel. Airline Health Certificate: Some airlines may require an additional health certificate confirming your pet's health and fitness to travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Track My Pet During Transport To Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When you travel with us, we provide you live photo and video updates of your pet during the entire travel process, so you can stay connected and informed about your best friend’s journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Pet-Friendly Accommodations In Qatar?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are pet-friendly accommodations in Qatar, but they may be limited in number and may require additional fees or restrictions. Some hotels in Qatar allow pets to stay with their owners, but it's important to check with the hotel before booking to confirm their pet policy, any fees or restrictions, and any required documentation, such as health certificates or vaccination records.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportNewZealand: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How much does it cost to transport a pet to New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet Transport costs to New Zealand can vary depending on the breed, size of the pet and the route. Transport from countries other than Australia can range from 10,000 to 30,000 NZD.',
        },
      },
      {
        '@type': 'Question',
        name: 'What are the requirements for importing a pet to New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The pet import requirements depend on the country of origin and the category of the country you are importing pets from. You can find detailed information on the Ministry for Primary Industries (MPI) website, which outlines the step-by-step process for bringing cats and dogs to New Zealand.  Here are some key requirements:  Microchip: The pet needs to be microchipped for identification purposes.  Vaccinations: The pet must have up-to-date vaccinations, including rabies vaccination, and a veterinary certificate to prove it.  Blood test: Depending on the country of origin, your pet may require a blood test to ensure it is free of diseases such as rabies.  Import permit: You must apply for and obtain an import permit from MPI prior to your pet's arrival in New Zealand.  Quarantine: All cats and dogs must undergo a minimum of 10 days of quarantine on arrival in New Zealand.",
        },
      },
      {
        '@type': 'Question',
        name: 'How long does it take for a pet to enter New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Generally, it takes a minimum of 2-3 months and a maximum of 6 months for the entire process. The time duration depends on the country of origin and the type of pet including breed. During this period, pets must undergo a series of tests, treatments, and quarantine periods to ensure they meet the strict health and safety requirements of New Zealand's Ministry for Primary Industries (MPI). It is important to begin the process well in advance of your intended travel dates to avoid delays and ensure that all requirements are met.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are there any quarantine requirements for pets entering New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If you are planning to bring your pet to New Zealand, Yes, there are quarantine requirements that you need to keep in mind. Pets arriving from Group 2 or 3 countries need to spend a minimum of 10 days in quarantine. These quarantine requirements are in place to protect the environment and the creatures of New Zealand. It's recommended to make arrangements with an approved quarantine facility in advance.",
        },
      },
      {
        '@type': 'Question',
        name: 'What type of health certificate is required for a pet entering New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "An import health certificate is required for pets entering New Zealand. The health certificate must be completed by an official veterinarian in the country of origin and is specific to the group country they are originating endorsed by USDA or CFIA.  For popular countries, the health certificate requirements are as follows:  Australia: An official health certificate is required, along with a declaration by the owner or agent that the pet has not been in contact with livestock or other animals that have not met the entry requirements for New Zealand.  United States, Canada, and Europe: An official health certificate is required, along with a rabies vaccination certificate.  United Kingdom: An official health certificate is required, along with a declaration by the owner or agent that the pet has not been in contact with livestock or other animals that have not met the entry requirements for New Zealand, and a rabies vaccination certificate.  It's important to check the requirements for the specific country of origin as they may vary. Your pet transport service can help you obtain the necessary health certificates and ensure that all requirements are met.",
        },
      },
      {
        '@type': 'Question',
        name: 'How much does it cost to import a pet from Australia to New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "There are many factors that need to be considered including pet breed, size, route, and transport methods like shipping or airlines. Generally, a standard one-way cost ranges from $1,500 to $3,500 NZD including necessary health checks, vaccinations, and other requirements needed for importing a pet. It's best to request a quote from us where we will be able to provide valuable information based on your needs and requirement for pet transport to New Zealand.",
        },
      },
    ],
  },
  dogTransport: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Fly A Dog?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of flying a dog varies depending on several factors, including the route, the dog's size and breed, and the airline. Domestic flights within Australia for a small dog can cost between $80-$200, while larger dogs can cost between $300-$500. The cost of an international flight can range from $500 to $3000 or more, depending on the destination, airline, and other factors. It is important to note, however, that these are only estimates, and the final cost will depend on individual circumstances. It is best to contact for a personalised quote based on your pet's requirements and travel plans.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Best Way To Transport A Dog?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The best way to transport a dog is determined by where you are going to and from. Air travel is the quickest and most efficient mode of pet transportation. When travelling locally, however, road transportation is usually the best option.  If you are planning to transport your pet internationally or interstate by air, we can provide the necessary crates, handling, and documentation for air travel. It is important to ensure that our pet is comfortable and safe while being transported and that all requirements are met.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Dog For A Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Best to ensure your pets are as calm as possible in their upcoming transport. Given animals require to be created, it helps if they have prior crate training experience. If pets experience high levels of stress, it's best to try Adaptil collars or Feliway spray to assist with stress before, during, and after transport. The night before transport it is best to have your pets secured within the house so there are no issues on the day (such as cats disappearing). On the day of transport ensure they’ve had plenty of time to go to the bathroom and have fasted for at least 8 hours prior to travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting A Dog On A Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Requirements for transporting a dog on a plane will depend on its breed and location of transport. Majority of the time they are only required to be fit and healthy. Some locations may require permits, certificates, or veterinary requirements for entry.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Kind Of Paperwork Do I Need To Bring When Travelling With My Dog?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Generally, you will only require your photo ID and Airway Bill number, but depending on your destination you may be required to show permits, certificates or veterinary paperwork at the airport.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Recommended Crate Sizes For Transporting A Dog By Car Or Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'There are very strict IPATA requirements around the size of transport carriers. Pets need to fit comfortably within the crates, allowing 5 cm from the top of their head to the top of the crate, be able to lay down comfortably without their paws being squished against the crate and be able to turn around comfortably without restrictions.',
        },
      },
    ],
  },
  shipping: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Documents Are Needed For Pet Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The required documents vary depending on the destination country, but generally, you'll need a health certificate, vaccination records, and an import permit. Our team at Dogtainers can guide you through the specific documentation needed for your pet's journey.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Book Pet Shipping Services?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "It's recommended to book as early as possible for international travel, but at least one month is required for most destinations. This is because most countries have specific requirements that may take time to fulfil, so booking in advance ensures a smoother, less-stressful process.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Breed Restrictions For Pet Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, certain airlines and countries have restrictions on specific breeds due to health or safety concerns. These are generally known as “dangerous breeds” and may include the American Pit Bull Terrier, Fila Brasileiro, and Dogo Argentino. Our team can provide information on any breed-specific regulations.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Factors Determine The Cost Of Pet Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of pet shipping is influenced by various factors, including your pet's size, weight, intended destination, chosen mode of travel, timing within the year, selected route, and more. You can get a personalised, free, no-obligation quote from Dogtainers based on your pet's specific details.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Ship A Dog Or Cat Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost varies widely depending on factors such as your pet’s size, the origin and destination, and how popular a route it is, but in general, international flights generally range from $4,000 to $10,000 AUD. However, it is not unusual for it to cost upwards of $10,000 to ship a pet internationally, especially if they are a large breed. To get a free, accurate, no-obligation estimate, please reach out to our team with your pet's details and travel requirements.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For International Pet Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe. Some countries such as Australia, Fiji, Hawaii, Hong Kong, Iceland, Japan, Malaysia, New Caledonia, New Zealand, Singapore, South Africa, and Taiwan, may have quarantine requirements for pets. Our team is well-versed in international regulations and can provide guidance on specific quarantine rules.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Book Pet Shipping Services For Multiple Pets At Once?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, we can arrange transportation for multiple pets. You can get a free, no-obligation quote based on your specific circumstances, so no matter how many pets you’re wanting to ship, we can help.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Ship My Pet If They Have A Medical Condition?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In many cases, pets with medical conditions can still be transported. Our team can provide recommendations for how best to safely transport your pet. Get in touch with us as 1300 13 52 52 or get a free-no obligation quote.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Alternatives To Air Travel For Pet Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ground transportation can be an alternative for shorter distances. However, air travel is usually the best option for longer journeys as it is typically faster, cheaper, with more trips scheduled on any given day. However, if you have an extra-large breed, snub-nosed breed, or a pet with a medical condition, driving may be the better option for you.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Requirements For Shipping Exotic Pets Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ground transportation can be an alternative for shorter distances. However, air travel is usually the best option for longer journeys as it is typically faster, cheaper, with more trips scheduled on any given day. However, if you have an extra-large breed, snub-nosed breed, or a pet with a medical condition, driving may be the better option for you.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Use My Own Pet Crate For Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Certainly, you are welcome to use your own crate, provided it adheres to the safety and comfort standards set by airlines and international regulations. It's essential that your pet's crate follows the specifications outlined by the International Air Transport Association (IATA) and offers ample space for your pet to sit, stand, lie down, and turn around comfortably. Please note that if these requirements are not met, the airline may not allow your pet to board the flight.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Send My Bird/Fish/Snake Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Certainly, we can arrange international transportation for a variety of pets, including birds, fish, and snakes, in addition to cats and dogs. Our experienced team is well-versed in the specific requirements that different animals have, as well as the import regulations of various countries. To learn more about your options, please don't hesitate to contact us today at 1300 13 52 52 or request a free quote with no obligation.",
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Have To Travel On The Same Flight As My Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "No, in fact, you probably won’t. Typically, there is limited space available for pets on each flight, while there are numerous seats for passengers. If you prefer to be on the same flight as your pet, our services can be customised to align with your travel arrangements. However, it's important to note that planning ahead and booking well in advance would be necessary for this option.",
        },
      },
    ],
  },
  locationSydneySyd: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does Pet Transport To NSW Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In general, domestic pet transport within Australia typically ranges from around $200 to $1,000 AUD. Shorter distances or local transport within NSW may be on the lower end of this range, while longer distances or specialised services can be more expensive. We offer both domestic and international transportation services to and from NSW and can provide you with a quote based on your specific needs. You can contact us directly at 1300 13 52 52 or get a free no-obligation quote.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting A Pet To Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting a pet to Sydney involves adhering to regulations and requirements to ensure safety. Key requirements include obtaining a health certificate from a licensed veterinarian to confirm good health and absence of contagious diseases. Pets must be microchipped and wear collars with identification tags. Up-to-date vaccinations, including rabies, are mandatory. Suitable travel crates that are sturdy, well-ventilated, and spacious enough for pets to move comfortably are necessary during transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Safety Measures For Transporting Pets To NSW?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To ensure the well-being and comfort of pets during transport to New South Wales, several safety measures should be taken. These include choosing an IATA-approved, sturdy, well-ventilated, and spacious travel crate. Cleaning and disinfecting the crate beforehand is important. Providing water in a spill-proof container and adding bedding or familiar items can help keep the pet comfortable. Sedating pets is generally discouraged unless advised by a veterinarian. Monitoring the temperature is crucial to prevent overheating or excessive cold. Proper handling is necessary to avoid injury or stress. Seeking assistance from a licensed pet transport company, like Dogtainers Sydney, is advisable for complying with safety regulations.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet Internationally From Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. You can transport your pet internationally from Sydney, but the requirements and regulations will vary depending on the destination country. When transporting a pet internationally from Sydney, you will need to comply with the import requirements of the destination country, which may include obtaining health certificates, vaccinations, and other documentation. You should also check with the airline or transport provider regarding their rules and regulations for transporting pets, as some may have restrictions on the size and type of crate or carrier allowed on board. It is recommended to work with a professional pet transport company, such as Dogtainers, who can provide guidance and assistance in navigating the complex regulations and requirements for international pet transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is the Process for Transporting a Pet by Air to or from Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Transporting a pet by air to or from Sydney involves several steps. Research airline requirements for crate size, breed restrictions, and check-in procedures. Choose a suitable crate, prepare it with absorbent material, a water bowl, and a label with your pet's information. Visit the veterinarian for a health check, certificate, and vaccinations. Book your pet's ticket in advance. Transport your pet to the airport with time for check-in. Pay fees and say goodbye. At the destination, collect your pet from the airline's cargo or baggage claim area. Dogtainers can handle as much or as little of this process as you like.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Transport a Large or Giant Pet to Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting large or giant pets to Sydney requires careful planning and consideration. Finding an appropriately sized and IATA-compliant crate is crucial, as it must be sturdy, well-ventilated, and spacious enough for the pet to stand and turn comfortably. Custom crates may be necessary. Airlines strictly enforce these requirements, and pets cannot board if the crate is incorrect. If you are unsure about the right crate, contact us at 1300 13 52 52 for our expert advice.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Transport An Exotic Pet To Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting an exotic pet to Sydney can be a complex process that often necessitates the help of a professional pet transport company like Dogtainers. We offer guidance and support in navigating the specific regulations and requirements involved in transporting exotic pets. We can also assist in arranging suitable flights or ground transportation for a smooth and safe journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport Multiple Pets at the Same Time to and from NSW?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting multiple pets together to and from NSW is possible, but certain factors should be taken into account. Consider the size, temperament, and compatibility of the pets with each other. It is advisable to use separate crates or carriers for each pet to ensure their safety and comfort during transport. Additionally, be aware of any regulations or guidelines set by the transport provider, as they may have restrictions on the number of pets allowed or require additional documentation or permits for transporting multiple pets.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Book a Door-to-Door Pet Transportation Service in Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'You can book our door-to-door service by calling us on 1300 13 52 52 or get your personalised free no-obligation quote.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet in a Crate or Carrier During Travel in Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When traveling in Sydney, it is both possible and advisable to transport your pet in a crate or carrier for their safety and well-being. This applies to both car travel and other modes of transportation. It is essential to secure the crate or carrier properly in the vehicle to prevent any movement. Additionally, make sure the crate or carrier is well-ventilated and comfortable for your pet throughout the journey.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportAustraliaGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Is The Process And Cost Of Pet Transport From The UK To Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The process of pet transport from the UK to Australia involves obtaining an import permit, fulfilling health and vaccination requirements, acquiring a crate, booking transportation, and arranging for quarantine upon arrival. The cost can range from approximately £1,000 to £3,000 or more, depending on individual circumstances.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does It Take To Transport A Pet From The UK To Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The duration of pet transport from the UK to Australia can vary depending on various factors such as the type of plane, routing, and any layovers. Typically, it takes around 20-30 hours for the journey, including the time spent in transit and any required layovers.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Requirements Or Documentation Needed For Pet Transport To Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, there are specific requirements and documentation needed for pet transport to Australia. This includes an import permit, veterinary health certificates, proof of microchipping, rabies vaccination and blood test results, and parasite treatments. It is important to consult with a reputable pet transport service, like Pets Abroad UK, to ensure compliance with all requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet In The Cabin With Me?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No major airline in Australia currently allows non-service animals in the passenger cabin. Pets are transported in the cargo hold, which is specifically designed to provide a safe and comfortable environment for pets during the journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Quarantine Regulations And Procedures For Pets Entering Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Australia has strict quarantine regulations and procedures for pets entering the country. Upon arrival, pets are transferred to a designated quarantine facility in Melbourne, where they undergo a mandatory quarantine period, typically lasting between 10 and 30 days. During quarantine, pets are monitored, undergo health checks, and may receive any necessary treatments to ensure they meet the requirements for release.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Are Pets Cared For During The Journey To Ensure Their Safety And Well-Being?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When entrusting your pet to a reputable pet transport service, they ensure that your furry companion is provided with a temperature-controlled environment, adheres to a consistent feeding and watering schedule, and receives expert handling to guarantee optimal care throughout the transportation process.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Track The Progress Of My Pet During The Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Some pet transport service providers offer tracking services that allow you to monitor the progress of your pet during transport. This can provide reassurance and keep you informed about your pet's whereabouts and well-being throughout the journey.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Happens If My Pet Has Special Dietary Or Medical Needs During The Journey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your pet has special dietary or medical needs during the journey, it is important to communicate this to the pet transport service provider in advance. They can make necessary arrangements to accommodate these needs, including providing specific food, medications, or additional care as required.',
        },
      },
      {
        '@type': 'Question',
        name: 'Is Pet Transport To Australia Safe And Reliable?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet transport to Australia, when done through reputable and experienced service providers, is generally safe and reliable. It is important to choose a trusted pet transport company with a track record of successfully transporting pets to Australia, like Pets Abroad UK. Working with professionals who understand the regulations and requirements can help ensure a safe and reliable transport experience for your pet.',
        },
      },
    ],
  },
  flyPets: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Can My Dog Or Cat Fly With Me?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can fly with your dog or can. However, it's important to note that they are generally not permitted to travel in the cabin on major Australian airlines. Only service animals are permitted to accompany passengers in the cabin during flights. All other pets, including dogs and cats, are required to be transported in the designated cargo hold area of the aircraft.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements And Restrictions For Flying With Pets? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The requirements and restrictions for flying with pets vary depending on the airline and destination. Generally, you will need to ensure that your pet is in good health, up-to-date on vaccinations, and has the necessary travel documentation, including a health certificate. Some airlines may have breed restrictions or specific rules for certain destinations.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Book A Flight For My Pet? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To book a flight for your pet, it is recommended to get in touch with a reputable pet transportation company. These specialised companies have the expertise and resources to handle all aspects of your pet's travel arrangements. They will guide you through the process, provide information on pet reservations, fees, and any specific requirements for pet travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Costs Associated With Flying Pets? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The costs associated with flying pets can encompass a range of factors including pet flights, crate rental, quarantine, vet checks, vaccines, blood work, and quarantine and boarding, if necessary. These expenses may vary depending on the airline, destination, and the size of your pet. To get an accurate quote and detailed information about the costs, it is recommended to get in touch with a reputable pet transportation company.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Specific Airlines That Are More Pet-Friendly?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Some airlines are known for being more pet-friendly and offering better services and accommodations for pets. Examples include airlines like Virgin and Qantas, which have pet-friendly policies and amenities. ',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Guidelines For Transporting Larger Pets In The Cargo Hold?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Guidelines entail the use of an airline-approved crate that offers adequate ventilation and security. It is also essential to provide water dishes within the crate and attach clear identification for easy identification. In cases where a pet is too large for air travel, a practical alternative is to opt for road transportation facilitated by a trusted pet transportation company, such as Dogtainers. ',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Air Travel? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Preparing your pet for air travel involves several important steps. These include acclimating your pet to their travel crate, ensuring they are comfortable and familiar with it, gradually increasing their crate time, and providing a calming environment during the journey. It's advisable to consult with your veterinarian for personalised advice based on your pet's needs.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Health Or Vaccination Requirements For Flying With Pets? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, most airlines require pets to be in good health and up-to-date on vaccinations. Additionally, some destinations may have specific health or vaccination requirements for incoming pets. It's crucial to reach out to a pet transportation company for guidance and assistance with your pet's travel arrangements. They will provide you with valuable information regarding health and vaccination requirements, as well as guide you through the necessary steps to ensure your pet's journey is safe and compliant with regulations.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are Sedatives Or Calming Methods Recommended For Pets During Flights?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Sedatives are generally not recommended for pets during flights, as they can pose risks and may lead to the rejection of your pet by airlines. Airlines have specific guidelines and restrictions regarding sedated pets. If you have concerns or questions about the use of sedatives, it is always best to consult with your veterinarian. ',
        },
      },
    ],
  },
  internationalPetTransportPetTransportUnitedArabEmiratesGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Travel To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Check the UAE's import requirements: The UAE has specific requirements for importing pets, including vaccinations, microchipping, and health certificates. Make sure to research these requirements well in advance to avoid any delays or issues with your pet's travel. Visit your vet: Before your trip, take your pet to the vet for a checkup and to obtain any necessary vaccinations or health certificates required by the UAE. Choose a pet-friendly airline: Not all airlines allow pets, and those that do may have different policies and procedures. Research your options and choose an airline that has experience with pet travel and offers suitable accommodations for your pet. Get a suitable travel crate: Your pet will need an IATA-specified crate for travel. Make sure the crate is the appropriate size for your pet and meets the airline's requirements. Get your pet accustomed to the crate: It's essential to prepare your pet for travel by getting them used to their travel crate well in advance. This will help reduce their anxiety during the journey.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need For My Pet To Travel To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To travel to the UAE with your pet, you will need to provide the following documents: Health Certificate: The certificate must be issued by a licensed veterinarian and include your pet's name, breed, age, sex, and microchip number. It should also state that your pet is healthy and free from contagious diseases. Vaccination Records: Your pet must be up-to-date on all required vaccinations, including rabies. The vaccination records should be included in the health certificate. Import Permit: You will need to obtain an import permit from the UAE Ministry of Climate Change and Environment (MOCCAE) prior to travel. Microchip: Your pet must be microchipped with a 15-digit ISO microchip. The microchip number should be included in the health certificate. Flight Booking: You will need to provide a copy of your pet's flight booking, including the flight number, date of travel, and airline name.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Restrictions On Pet Breeds Allowed To Enter UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The following dog breeds, mixed breeds of these breeds and their hybrids are prohibited for import in the United Arab Emirates: All Kinds of Pit Bull, Japanese Tosa, Argentinian Fighting Dog, Brazilian Fighting Dog, Wolf Dog Hybrid, and American Staffordshire terrier.',
        },
      },
      {
        '@type': 'Question',
        name: "Can I Track My Pet's Journey During Transport To The UAE?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can track the plane in real-time to see how far along your pet is on their journey. We will also provide you with real-time updates on either side of the landing so you know they departed and arrived safely.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Carriers For Air Travel To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The UAE has specific requirements for pet carriers used for air travel. Size and Type: The carrier should be an IATA-certified pet carrier, and it should be large enough for the pet to stand up, lie down and turn around comfortably. The carrier should also have proper ventilation, a secure door, and a leak-proof bottom. Material: The carrier should be made of sturdy and durable materials that can withstand the rigours of air travel. Fibreglass, metal, solid wood, plywood, or rigid plastic are recommended. Labelling: The carrier should be labelled with the pet's name, owner's name, and contact information, as well as any special handling instructions or medical information. Absorbent bedding: To provide a more comfortable travel experience for your pet, place an absorbent pad on the floor of the crate. Water dishes: The carrier should have a spill-proof water container. Security: The carrier should be secured with metal bolts, and any additional locking mechanisms, such as cable ties or zip ties, should be securely fastened.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process For Customs Clearance For My Pet At UAE Airports?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The customs clearance process for pets in the UAE typically involves the following steps: Pet owners must provide all required documentation, including a valid health certificate, vaccination records, and microchip information, to customs officials. Pets are typically subject to inspection by customs officials to ensure that they meet all necessary requirements for entry. This may include a physical examination and a review of the pet's health and vaccination records. There may be customs duties and taxes applied to pets being imported into the UAE, and pet owners will be required to pay these fees to customs officials. Once all necessary requirements have been met, including inspection, quarantine, and payment of duties and taxes, the pet will be released to the owner or your pet transport company representative.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Book Pet Transport Services To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The specific amount of time required for booking will depend on a few factors, including the time of year, the airline or pet transport company, and the destination in the UAE. As a general rule, it is best to book pet transport services at least 4-6 weeks in advance, and even earlier during peak travel seasons or holidays. This will give you enough time to prepare your pet for travel, gather all necessary documentation, and ensure that you can secure a spot on your desired flight.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Cost Of Pet Transport To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of pet transport to the UAE can vary depending on a few factors, including the size and weight of your pet, the origin location, the mode of transportation, and any additional services or requirements. As a rough estimate, the cost of pet transport to the UAE can range from £500 to AED £2,500 or more. It is also important to factor in any additional expenses, such as vaccinations, health certificates, and quarantine fees, that may be required for your pet to enter the UAE.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process For Quarantine In UAE For Pets Entering The Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The UAE does not have a quarantine requirement for pets entering the country if they meet all the stated health requirements. The health requirements for pets entering the UAE include a valid health certificate, a microchip, and a current rabies vaccination. ',
        },
      },
      {
        '@type': 'Question',
        name: "Can I Bring My Pet's Favourite Toys Or Blankets During Transport To The UAE?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can generally bring your pet's favourite toys or blankets with them during transport to the UAE. Bringing familiar items from home can help reduce stress and anxiety for your pet during the journey. However, it's important to check with us beforehand, as some airlines may have specific guidelines on what can be brought on board. If your pet has an accident in their travel crate, their items will be destroyed by the airline.  ",
        },
      },
      {
        '@type': 'Question',
        name: 'What If My Pet Is Anxious Or Fearful During Transport To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your pet is anxious during transport to the UAE, there are several steps you can take to help alleviate their stress and make the journey as comfortable as possible. Familiarise your pet with their travel crate before the trip. Leave it out in your home and encourage your pet to spend time in it so that they become comfortable and relaxed inside. Provide your pet with familiar items from home, such as their favourite toys or blankets. This can help to provide a sense of comfort and familiarity during the journey. Stay calm and positive around your pet, as they can easily pick up on your emotions. Providing reassurance and positive reinforcement can help to reduce anxiety in pets. Consider using a pet transport company that offers personalized care and attention to your pet during the journey. Reputable pet transport companies will provide updates and support throughout the journey to ensure that your pet is safe and comfortable.',
        },
      },
    ],
  },
  internationalPetTransportPetTransportUnitedArabEmiratesNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Pet Transport To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To transport a pet to the UAE, you will require a valid import permit, an up-to-date vaccination record, including rabies vaccination, an ISO-compliant microchip, and a health certificate. Some specific countries may have additional requirements, such as blood tests or parasite treatments.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Pet To The UAE From New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet shipping costs to the UAE from New Zealand can vary depending on several factors, including the airline, size of the pet, travel distance, and additional services required. However, a rough dollar estimate is somewhere between $10,000 and $15,000 NZD. Dogtainers can provide you with a free, no-obligation quote based on your specific circumstances on our website.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring My Pet To The UAE With Me?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can bring your pet to the UAE. However, there are certain requirements and procedures that need to be followed for pet transport. Ensure that you have the necessary documentation, including a valid import permit and health certificate, and meet the import requirements set by the UAE authorities.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Quarantine Procedures For Pets in the UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. There are no mandatory quarantine procedures for pets arriving in the UAE. However, they may be subject to inspection upon arrival.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Allow Pets To Travel To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Several airlines allow pets to travel to the UAE, but their pet transport restrictions may vary. Some airlines that commonly transport pets to the UAE include Etihad and Singapore Airlines. However, it is important to check with the specific airline regarding their pet travel policies, including any breed or size restrictions.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Breed Restrictions For Pet Transport To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. Many ‘dangerous breeds’ and ‘bully breeds’ are banned in the UAE. These include Pit Bulls, Doberman Pinschers, Rottweilers, and more.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does Pet Transport To The UAE From New Zealand Take?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Flights from New Zealand to the UAE, without layovers, typically take a minimum of 18 hours. When you choose Dogtainers, we will ensure that your pet travels to the UAE on the most direct route possible, minimising layovers. If there are layovers, we can arrange for pet boarding during those layovers to ensure their comfort and well-being.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need For Pet Transport To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Typically, you will need a valid import permit, an up-to-date vaccination record, and a health certificate issued by a licensed veterinarian in your home country.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet To Abu Dhabi Or Dubai?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can transport your pet to Abu Dhabi or Dubai. However, you need to follow the regulations and requirements set by the UAE authorities, including obtaining the necessary permits and documentation for pet transport.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Regulations For Transporting Dogs Or Cats To The UAE?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. Both cats and dogs have distinct vaccination prerequisites that need to be fulfilled. It is necessary to obtain a valid import certificate, health certificate, and ensure that they are implanted with an ISO-compliant microchip. Furthermore, certain countries might have additional requirements, such as completing supplementary tests and treatments.',
        },
      },
    ],
  },
  petTravelGlobalGB: {
    '@context': '',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To prepare your pet for travel, start by researching the specific requirements and regulations for your destination to ensure compliance. Your pet will need to be up-to-date on the vaccinations required by the destination country and receive a thorough health check-up. Acclimate them to their travel carrier, gradually introducing them to it with positive reinforcement. You will likely require a certificate of good health and a record of all vaccinations. Pets Abroad UK offers global pet transportation services, and can help you at all stages of the pet relocation process.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents And Vaccinations Are Needed For Pet Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The required documents and vaccinations for pet travel vary depending on the destination and the specific requirements of that country. However, common documents include a pet passport, certificate of good health, and proof of up-to-date vaccinations. Research the destination country's regulations or consult with our team at Pets Abroad to ensure you have the necessary documentation and vaccinations for your pet's travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Travel With My Pet In The Cabin Of The Plane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Whether or not pets are allowed to travel in the cabin of the plane depends on the airline's specific policies and regulations. Some airlines allow small pets to travel in the cabin under certain conditions, such as size restrictions and the use of an airline-approved carrier. Larger pets may need to travel in the cargo hold. If you’d like to know whether your pet can travel in the cabin with you, contact Pets Abroad UK to discuss your travel options. ",
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Do If My Pet Has Special Needs Or Medical Conditions During Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If your pet has special needs or medical conditions, it is important to communicate this information with your pet transportation company during the booking process. At Pets Abroad UK, our team will work closely with you to understand your pet's requirements and make appropriate arrangements to ensure their comfort and well-being during travel. It may involve additional accommodations, special handling instructions, or coordination with veterinary professionals to address specific medical needs.",
        },
      },
    ],
  },
  petFlights: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Are Pets Allowed in the Cabin with Me?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In most cases, no. Generally, only service animals and other airline-approved animals are allowed in the cabin while all other animals are held in a compartment which is usually at the rear of the plane. Pets travel in a temperature-controlled environment that is set to approximately 18 degrees celsius. The compartment is pressurised in the same way the passenger cabin is and is dimly lit. With the gentle hum of the aircraft, pets often spend the time dozing before reaching their destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Fly With My Pet Domestically Within Australia? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can fly with your pet domestically within Australia. The two main pet-friendly domestic airlines in Australia are Virgin Australia and Qantas. Dogtainers works closely with both airlines and can handle your booking to anywhere in Australia.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do Pets Need Vaccines To Travel Domestically in Australia?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "While it's not a legal obligation, we highly recommend ensuring your pet is up-to-date with vaccinations. This precaution is taken because animals are more susceptible to illnesses during travel. We also recommend ensuring your pet is microchipped and the information is up-to-date, as this makes it easier to trace lost and stolen pets.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need For My Pet To Fly Domestically? ',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When flying domestically, you won’t require any documents. However, you may want to get a certificate of good health from your veterinarian, as pets are permitted to board based on whether they look fit and healthy.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Breeds Or Sizes Of Pets That Can Fly Domestically?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'American Pitbull Terrier, Japanese Tosa, Dogo Argentino, and Fila Brasileiro are all banned breeds in Australia and therefore cannot fly. There are restrictions for brachycephalic breeds (snub-nosed breeds), like pugs. You will need to use a pet transportation company in order to transport your brachy breed.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Health Considerations For Flying With Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To be able to fly, pets need to be “in good health”. You can get a certificate of good health from your vet. This will be required on international flights, and may be requested on domestic flights. Most airlines require pets to be older than 8 weeks, and have additional requirements for pets over 12 years. There are also additional requirements for brachycephalic breeds (snub-nosed breeds), like pugs.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Travel Restrictions Or Quarantine Requirements For Flying With Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Within Australia, there are generally no quarantine requirements for domestic pet travel. However, if you're travelling from or to certain regions, you might need to check for any specific restrictions.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Fly With A Pet Domestically?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of flying with a pet domestically varies depending on the airline, the size of your pet, and the route. However, a rough estimate can range from $450 to $1,500 AUD. ',
        },
      },
      {
        '@type': 'Question',
        name: 'Can My Pet Fly In The Cabin With Me On International Flights?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No Australian airline currently permits pets in the cabin. Currently, only service animals are allowed in the cabin. ',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The General Requirements For International Pet Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'International pet travel involves complex regulations, including specific vaccinations, health certificates, and microchipping. Additionally, some countries require pet quarantine. Many airlines require or strongly advise you to work with a pet transport company that specialises in international travel.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Book A Pet Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "It's recommended to book your pet's flight well in advance, as airlines have limitations on the number of pets allowed per flight. Get in touch with your pet transportation service provider at least one month in advance.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are Pets Monitored During The Flight For Their Safety And Well-Being?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Airlines do not typically provide real-time monitoring of pets in the cargo hold during the flight. The cargo hold is designed to maintain appropriate temperature, pressure, and ventilation levels, and airlines take precautions to ensure pets' safety. However, continuous monitoring of pets in the cargo hold is not a standard practice on commercial flights.",
        },
      },
    ],
  },
  petFlightsGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Fly A Pet Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of flying a pet internationally varies depending on the airline, destination, and the size of the pet. Prices can range from a few hundred to several thousand pounds. It's essential to check with your pet transport provider for the costs associated with international pet flights.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Pet-Friendly Airlines In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, there are pet-friendly airlines in the UK. However, generally only service animals are allowed to travel in the cabin with passengers. Some airlines allow small pets to travel in the cabin on flights leaving the UK. Before making travel arrangements, it is advisable to confirm the current pet policies of each airline.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Take My Dog On A Plane In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can take your dog on a plane in the UK, provided they travel in the pet section of the cargo hold. However, this depends on the airline's pet policy. Some airlines allow small pets to travel in the cabin with you when leaving the UK. Check with the airline for their specific regulations or get in contact with us to learn more.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Flying With A Pet In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When you are flying with a cat or flying with a dog in the UK, there are a number of requirements you must meet. Cats will require an F3 vaccine, while dogs will need a C3 vaccine. Additionally, they must be microchipped and have a rabies vaccine. For their safety during the flight, they must be transported in a crate that complies with the IATA specifications.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Book A Pet Flight Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To book a pet flight within the UK, it’s best to get in contact with a pet transport provider, such as Pets Abroad UK. Inform them of your plans and they will guide you through the booking process, including providing information on pet travel fees, required documentation, and any other relevant details.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On Flying With Certain Breeds Of Dogs In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The United Kingdom maintains a list of dog breeds that are prohibited from importation and ownership within the country. These breeds are subject to restrictions due to safety concerns and are often referred to as 'banned breeds.' The prohibited breeds include the Pit Bull Terrier, Japanese Tosa, Dogo Argentino, and Fila Brasileiro.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Best Way To Prepare My Pet For A Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To prepare your pet for a flight, start by acclimating them to their travel crate or carrier. Gradually introduce them to it and associate it with positive experiences. Make sure your pet is comfortable with being confined for an extended period. Additionally, visit the vet for a health check-up, ensure all vaccinations are up-to-date, and obtain any required travel documents.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring My Pet To The Cabin With Me On A UK Flight?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Chances are, no. While service animals are usually allowed in the cabin with their handlers, most other pets are required to travel in the cargo hold. Nevertheless, certain airlines may permit small pets to accompany their owners in the cabin when travelling outside of the UK. For further details, it's recommended to reach out to Pets Abroad UK.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Direct Flights Available For Pets Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Direct flights for pets within the UK are available, but not all routes may have direct options. Some flights may involve layovers or transfers, depending on the destination and airline. When you choose Pets Abroad UK as your pet transport partner, we select the most appropriate route for your pet, minimising layovers and transfers.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Options For Pet Cargo Flights In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Several airlines offer pet cargo services. These services involve transporting pets in a secure and climate-controlled area within the cargo hold of the aircraft. Airlines like British Airways, Lufthansa, and Virgin Atlantic typically provide these options, but specific availability and regulations may vary.',
        },
      },
    ],
  },
  petTransportImportServicesGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Which Countries Accept Pet Passports?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Great Britain accepts pet passports from the following countries (known as Part 1 listed countries): Andorra, Azores and Madeira, Canary Islands, French Guiana, Gibraltar, Greenland and the Faroe Islands, Guadeloupe, Iceland, Liechtenstein, Martinique, Mayotte (French territory), Monaco, Norway, Réunion (French territory), Saint Barthélemy (French Territory), San Marino, Saint Martin (French territory), Switzerland, Vatican City State',
        },
      },
      {
        '@type': 'Question',
        name: 'Which Countries Accept Pet Health Certificates?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Great Britain accepts Great Britain pet health certificates from the following countries (known as Part 2 listed countries): Antigua and Barbuda, Argentina, Aruba, Ascension Island, Australia, Bahrain, Barbados, Belarus, Bermuda, BES Islands (Bonair, Saint Eustatius and Saba), Bosnia-Herzegovina, British Virgin Islands, Canada, Cayman Islands, Chile, Curaçao, Falkland Islands, Fiji, French Polynesia, Hong Kong, Jamaica, Japan, Malaysia, Mauritius, Mexico, Montserrat, New Caledonia, New Zealand, North Macedonia, Russian Federation, Saint Maarten, Singapore, St Helena, St Kitts and Nevis, St Lucia, St Pierre and Miquelon, St Vincent and The Grenadines, Taiwan, Trinidad and Tobago, United Arab Emirates, USA (includes American Samoa, Guam, Northern Mariana Islands, Puerto Rico and the US Virgin Islands), Vanuatu, Wallis and Futuna. Great Britain also accepts health certificates from Part 1 countries.',
        },
      },
      {
        '@type': 'Question',
        name: 'How To Travel With Pets To The UK From A Not ‘Listed’ Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To enter the UK from a “Not Listed” country, you will need to complete the following criteria after having your pet vaccinated for rabies: Your pet must have a blood sample taken at least 30 days after the rabies vaccination, your vet must send the blood sample to an EU-approved blood testing laboratory, the results of the blood test must show that the vaccination was successful, you must wait 3 months from the date the blood sample was taken before you travel, and you must get a copy of the test results in a health certificate.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Pet Import Regulations For Bringing Dogs to The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'As an overview, when bringing a dog into the UK, they can enter or return to England, Scotland, or Wales if they have been microchipped, vaccinated against rabies, treated for tapeworm, and have a pet passport or health certificate. Your dog may need to do a blood test if you’re not travelling from a Part 1 or Part 2 country. If you’re going to sell or transfer ownership of your dog, you will need to fill in a declaration.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Pet Import Regulations For Bringing a Cat to The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'As an overview, your pet cat can enter or return to England, Scotland, or Wales if they have been microchipped, vaccinated against rabies, and have a pet passport or health certificate. If you’re going to sell or transfer ownership of your cat, you will need to fill in a declaration.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does A Dog Have To Be In Quarantine in The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'As an overview, your pet cat can enter or return to England, Scotland, or Wales if they have been microchipped, vaccinated against rabies, and have a pet passport or health certificate. If you’re going to sell or transfer ownership of your cat, you will need to fill in a declaration.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Vaccinations Are Required For Pets Entering The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The core vaccination required is against rabies. The vaccination schedule might vary depending on the specific rabies vaccination your pet receives and the country they’re coming from.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Obtain A Pet Passport For My Animal?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To get an official ‘Pet Passport’, you must be from an authorised EU country. Then, your pet must be microchipped, vaccinated against rabies, and be treated for ticks, fleas and tapeworms. You will need a veterinarian's letter or certificate confirming fitness to travel and/or no obvious signs of disease, and a government certification that the veterinarian's export documentation and certificates are in order for travel. However, outside of the EU, “pet passport” is a phrase used to describe the documents that are required to import a pet into a country - not an officially recognised document. In these cases, you will often just need proof of vaccinations, microchipping, and a health certificate. However, this does vary by country, so it’s best to contact a professional pet transportation company to get information based on your specific circumstances.",
        },
      },
      {
        '@type': 'Question',
        name: 'Is There A Quarantine Period For Pets Arriving In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If the UK’s import procedures are followed, there is no quarantine period for pets arriving in the UK. In general, this requires your pet being microchipped, vaccinated against rabies, and having a pet passport or health certificate. However, further regulations can apply, so it’s best to contact a professional pet transportation service, like Pets Abroad UK.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring Exotic Pets Or Birds Into The UK, And Are There Specific Regulations For Them?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can bring exotic pets or birds to the UK, but specific regulations apply. These can include getting a health certificate, import permit, and compliance with CITES regulations (Convention on International Trade in Endangered Species), depending on your pet’s species and which country they're travelling from. Pets Abroad UK can guide you on how to bring your bird or other exotic pet into the UK based on your unique circumstances.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On The Type Or Breed Of Pets That Can Be Brought Into The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, the following breeds are banned in the UK: Pit Bull Terrier, Japanese Tosa, Dogo Argentino, Fila Brasileiro. These breeds cannot be imported or brought into the UK by plane, ship, or any other method.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Choose A Pet-Friendly Airline For Travel To The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Look for airlines with established pet transportation services, well-maintained cargo areas, and policies that prioritise pet safety and comfort. When you partner with us, we provide recommendations for pet-friendly airlines flying the most direct route to your destination. Use our free, no-obligation quote calculator to find out more.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Size And Type Of Pet Travel Crate Do I Need For My Animal?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The crate size should allow your pet to stand, turn around, and lie down comfortably. It should also meet International Air Transport Association (IATA) standards. Pets Abroad UK can provide guidance on airline-approved crate selection.',
        },
      },
    ],
  },
  locationBrisbaneBne: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Book Pet Transport Services From Brisbane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Booking our pet transport services from Brisbane is easy. Simply visit our website and fill in a free, no-obligation quote or contact our customer service team on 1300 13 52 52 to discuss your needs, receive a quote, and schedule your pet's transportation.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Average Cost Of Pet Transport Within Brisbane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of pet transport within Brisbane can vary depending on factors like the size of your pet, the distance, and specific requirements. However, prices often range from $90-$300. For an accurate estimate, please submit a free, no-obligation quote with us to get information tailored to you and your pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Specialised Pet Transport Options For Different Animals?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, we offer specialised transport options for various animals, including dogs, cats, birds, reptiles, and more. We also have dedicated teams for relocating pedigree pets and zoo animals. Our services are fully customisable and tailored to you and your pet. Get a free, no-obligation quote online today.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Accompany My Pet During The Transport Process?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If you’d like to fly on the same plane with your pet, we can organise this for you. However, pets and owners travel in different parts of the plane. Pets have their own, dedicated section of the cargo hold. This section is dimly lit to encourage pets to rest and set to a cool, 18 degrees Celsius. Please be aware that if you want to travel with your pet, it is essential to secure your booking well in advance, as the availability of pet spots on each flight is limited.',
        },
      },
      {
        '@type': 'Question',
        name: "What Safety Measures Are Taken To Ensure Pets' Well-Being During Travel?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "With more than 50 years of pet relocation experience, animal welfare has always been our top priority. Our pet safety measures include secure and comfortable pet crates, climate-controlled transportation, regular check-ins, and experienced handlers to ensure your pet's comfort and safety. If you’d like to know more about how we keep pets safe and secure, reach out to one of our friendly pet transport consultants on 1300 13 52 52.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does It Take To Transport A Pet From Brisbane To Sydney?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet transport from Brisbane to Sydney can vary depending on factors such as the mode of transportation and the specific route. Flying a pet from Brisbane to Sydney typically takes just under two hours, while driving them takes closer to 10. However, certain pets, particularly extra-large dogs, anxious pets, and brachycephalic breeds such as pugs, may find road travel more suitable and comfortable.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Direct Flights Available For Pet Transport In Brisbane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, Brisbane offers direct flights connecting to destinations both domestically and globally. We will work to find the most convenient and direct options for your pet's journey, taking into account various factors, including any specific health needs.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Paperwork And Vaccinations Are Required For Domestic Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Australia does not impose specific requirements for domestic pet travel. While it is strongly advisable to have your pet microchipped for their well-being, it is not mandatory. However, if your pet is staying at a boarding facility, it's important to note that cats will typically need a C3 vaccine, and dogs will require an F5 vaccine.",
        },
      },
      {
        '@type': 'Question',
        name: 'Do You Offer Door-To-Door Pet Transport Services In Brisbane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, we provide door-to-door pet transport services throughout the entire Brisbane area. Our team will pick up your pet from your location and deliver them safely to their destination, anywhere in Australia or around the world.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport Multiple Pets Together?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, we can accommodate the transportation of multiple pets together. Just let us know the number and types of pets you wish to transport, and we will make the necessary arrangements. Get a free, no-obligation quote today.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions For Transporting Certain Dog Breeds?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. In Australia, there are five banned dog breeds. These breeds are the Dogo Argentino, Fila Brasileiro, Japanese Tosa, American pit bull terrier or pit bull terrier, and the Perro de Presa Canario or Presa Canario. If you’d like to know more, please get in touch with us on 1300 13 52 52.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Transportation From Brisbane?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Preparing your pet for transportation involves ensuring they are healthy, have the necessary vaccinations for the destination, and are accustomed to their travel crate. We will provide you with a detailed checklist and guidance to prepare your pet effectively for their journey. Get a free, no-obligation quote online today.',
        },
      },
    ],
  },
  petTravelPreparationGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For International Travel To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Preparing your pet for international travel involves several steps, including obtaining the necessary documents, vaccinations, and acclimatisation to travel crates. It's essential to plan ahead and consult with a professional pet transport company, as these are usually recommended, and often required by airlines.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Are Required For Pet Travel To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Typically, you'll need a pet passport, microchip records, vaccination certificates (including rabies vaccination), and, if required, additional health certificates. Ensure all documentation meets the UK's entry requirements.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Specific Health Requirements For Pets Travelling To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, pets travelling to and from the UK must meet specific health requirements, including rabies vaccination and, for dogs, tapeworm treatment. Consult with your professional pet transport company for specific advice.',
        },
      },
      {
        '@type': 'Question',
        name: "How Do I Choose The Right Travel Crate For My Pet's Journey To And From The UK?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Select a travel crate that meets international airline regulations, is well-ventilated, and allows your pet to stand, turn around, and lie down comfortably. IATA-specified travel crates meet all of these conditions. Introduce your pet to the crate gradually to ensure they are comfortable with it. We can help size your pet so you pick the right crate.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Vaccinations Does My Pet Need Before Travelling To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Common vaccinations include rabies, C3 for cats, and C5 for dogs. Additional vaccinations may be required depending on your pet's specific needs and the destination.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Quarantine Regulations For Pets Entering And Leaving The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The UK generally does not require quarantine for pets that meet the specified entry requirements. However, it's essential to verify current regulations before travelling. If you do not pass import requirements, your pet can be quarantined for months at your expense.",
        },
      },
      {
        '@type': 'Question',
        name: "Can I Bring My Pet's Favourite Toys And Belongings During Travel To And From The UK?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Maybe, it depends on the airline. Some airlines will allow one small, soft toy and/or a blanket in your pet’s crate with them. To be safe, we recommend checking with the airline and bringing their favourite toy with you so they have something familiar when they land.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Offer Pet Travel Services To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Many airlines offer pet travel services to and from the UK, including British Airways, Emirates, Singapore Airlines, and Lufthansa. It's advisable to research airlines, their policies, and fees associated with pet travel, and choose one that suits your needs.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Should I Acclimate My Pet To The Travel Crate Before The Journey To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Gradually introduce your pet to the crate by placing their favourite items inside, offering treats, and increasing the time they spend in it. This helps them become accustomed to the crate and reduces anxiety during travel.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Restrictions On Certain Breeds Or Types Of Pets For International Travel To And From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Some countries and airlines may have restrictions on specific breeds or types of pets due to safety or health concerns. These are generally considered “dangerous breeds”. Get in contact with us and we can let you know which breeds are banned in your destination country.',
        },
      },
    ],
  },
  petTypesGB: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Documents Do I Need To Transport A Reptile Internationally From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Transporting a reptile internationally typically requires documentation such as CITES permits, health certificates, and import permits specific to the destination country. The exact requirements can vary, so it's crucial to consult with our experts who can guide you through the necessary paperwork and procedures.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport Other Pets Like Rabbits Or Guinea Pigs Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can transport small mammals like rabbits or guinea pigs internationally. Each country may have specific import regulations for these pets, including health checks and documentation. Our team can assist you in navigating these requirements and ensuring a smooth journey for your furry companions.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport Exotic Pets Like Birds Or Reptiles Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Absolutely, we specialize in transporting exotic pets, including birds and reptiles, internationally. We tailor our services to meet the unique needs of each species and handle all the necessary documentation and logistics to ensure a safe and comfortable journey for your exotic companions.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Restrictions On Transporting Small Mammals, Like Rabbits Or Guinea Pigs?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Different countries have varying restrictions on the import of small mammals. It's essential to research and comply with the regulations of your destination country. Our team can assist you in understanding and meeting these requirements to transport your small mammals safely.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Pet To Multiple Destinations During An International Move?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, we can accommodate multi-destination international moves for your pets. We'll work closely with you to plan the journey and coordinate transportation to multiple locations, ensuring a smooth transition for your pets.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Exotic Pet From The UK To Another Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, we have experience in transporting exotic pets from the UK to various countries worldwide. We can help you with the necessary permits, documentation, and logistics to ensure a safe and compliant international journey for your exotic pet.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Bird For International Travel From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Preparing your bird for international travel involves ensuring they have the appropriate crate or enclosure, are up-to-date on their vaccines, and have proper documentation. Our experts will guide you through the specific steps and requirements to make the journey comfortable and stress-free for your bird.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On Bringing Small Mammals Into The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The UK has strict regulations regarding the importation of small mammals. Rodents and rabbits will be put into quarantine for 4 months and will also require a rabies import permit. Our team can provide guidance on meeting these requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do You Handle The Transport Of Sensitive Or Elderly Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Transporting sensitive or elderly pets requires extra care and attention. Within our team, we have a veterinarian and two veterinary nurses who possess the expertise to address any medical or behavioral requirements your pet might have. We tailor our services to accommodate their specific needs, including comfortable accommodations, temperature control, and gentle handling. Our goal is to ensure that your sensitive or elderly pets travel safely and comfortably.',
        },
      },
    ],
  },
  petsFromQuarantineGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Long Is the Pet Quarantine Period in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The duration of the pet quarantine period in the UK can vary depending on your pet's country of origin and compliance with import regulations. It typically ranges from 21 to 180 days, with most pets falling within the shorter end of this spectrum.",
        },
      },
      {
        '@type': 'Question',
        name: "What Are the UK's Pet Import Regulations?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The UK has stringent pet import regulations, including microchipping, rabies vaccinations, blood tests, health certificate or pet passport, and treatments for tapeworm. Requirements may differ based on your pet's origin, so it's crucial to consult with a reputable pet relocation service for the specific regulations applicable to your situation.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Visit My Pet During Quarantine in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Generally, no. This is to maintain the integrity of the isolation process. However, you can get in contact with the specific quarantine facility for their policies on visitation and updates.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Exemptions to UK Pet Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Quarantine is not mandatory in the UK. If you follow the UK’s pet import process and requirements, your pet will not be required to quarantine. Get in touch with Pets Abroad UK to learn more about the UK’s pet import requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does Pet Quarantine in the UK Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of pet quarantine in the UK can vary depending on factors such as the length of stay, the facility, and any additional services required. It's advisable to contact the quarantine facility directly or consult with a pet relocation service for accurate cost estimates.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Vaccinations Are Required for Pets in UK Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The primary vaccination requirement for pets in UK quarantine is rabies vaccination. Specifics may vary depending on the country of origin and individual circumstances. Your pet will need to be microchipped and dogs will need to receive treatments for tapeworms as well.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can My Pet Be Released from the UK Quarantine Early?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Early release from quarantine is generally not allowed unless your pet meets specific exemption criteria. The standard quarantine period must be completed to ensure compliance with UK import regulations.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Is Needed for Pet Quarantine in the UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Documentation requirements include import permits, microchip details, rabies vaccination certificates, blood test results, and records of tapeworm treatments (for dogs). The precise documents needed may vary based on your pet's origin, so consult with a reputable pet relocation specialist for detailed guidance.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Special Accommodations for Exotic Pets in UK Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Quarantine facilities in the UK may offer accommodations for exotic pets, but these should be arranged in advance. If you’re bringing an animal protected by the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES) into Great Britain, you’ll need to apply for a permit or certificate.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Send Toys or Food to My Pet in the UK Quarantine?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Maybe. The possibility of sending toys or food to your pet while they are in quarantine can vary depending on the rules of the specific facility. It's important to reach out to your designated quarantine facility to inquire about their policies before sending anything - especially a beloved toy.",
        },
      },
    ],
  },
  shippingGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Arrange Pet Shipping From The UK To Another Country?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To arrange pet shipping, choose a reputable pet shipping company, like Pets Abroad UK. Contact them for assistance, and they will provide a comprehensive guide. In general, your pet will likely need to have or get: vaccinations (rabies, F3 for cats, C5 for dogs), a microchip, a Pet Passport or health certificate, and an IATA-specified crate that’s not too big or small. The company will organise flights, quarantine (if required), and customs clearance.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Are Required For Pet Shipping Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Dogs are required to have a microchip in the UK. Dogs in public spaces should also wear a collar and identification disc. The disc should have the owner's surname and address on it. Cats are not required to be microchipped.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Ship A Pet Internationally From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost varies based on factors like destination, pet size, transport method, and services needed. Use our complimentary, no-obligation online quote calculator for an estimate tailored to your specific circumstances.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Pet Shipping Regulations Or Restrictions In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes and no. When bringing pets into the UK from other countries, there are specific requirements such as health checks, vaccinations, and microchipping that must be met. However, if you have a pet already residing in the UK and plan to travel to another UK country, you generally have more flexibility. For instance, for pet dogs in public spaces, it's important to ensure they are microchipped and wear a collar.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process For Shipping A Pet By Air From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The pet shipping process includes selecting an airline, booking a suitable flight, and obtaining an IATA-approved crate that fits your pet comfortably. Ensure your pet is up-to-date with vaccinations and required health treatments for the destination. Arrange for customs clearance, address any quarantine needs, and plan for airport pickup. Pet shipping companies, such as Pets Abroad UK, offer comprehensive assistance throughout this process, ensuring a streamlined and stress-free experience.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Ship My Pet By Sea Or Land Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, ground or sea transport is an option for travel within the UK. There are ferry services and other sea transport options available for pet owners who want to travel with their pets or move them to different parts of the UK by sea. However, air transport is generally the fastest, cheapest, and easiest to organise. Contact a pet transport company, such as Pets Abroad UK, to discuss your options and preferences.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Ensure The Safety And Comfort Of My Pet During Shipping?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Schedule a visit to the veterinarian for a comprehensive health examination. Make certain that your pet has received all the necessary and suggested vaccinations required for your chosen destination. Acquire an appropriately sized crate that meets IATA specifications and commence crate training by placing familiar belongings inside and allowing your pet to become familiar with it. Gradually extend the duration of time your pet spends in the crate over a period. To guarantee your pet's safety during the journey, seek the assistance of a reputable pet transport company, such as Pets Abroad UK.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Recommended Pet Crate Size For Shipping In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The appropriate crate size varies according to your pet's size and breed. On our crate hire page, we offer detailed instructions on how to measure your pet accurately for the right travel crate size. As a general rule of thumb, the crate should provide enough space for your pet to stand, turn around, and lie down comfortably. For brachycephalic breeds like pugs, you’ll need to size up the crate to ensure their comfort and safety during travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For Pet Shipping In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Generally, no. Pets do not need to quarantine in the UK provided they follow all import rules. Pets travelling between UK countries do not need to quarantine at all. Pets Abroad UK can guide you on the import rules and how they vary based on your country of origin.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do Pet Shipping Companies Provide Door-To-Door Services In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, many pet shipping companies offer door-to-door services for added convenience, collecting your pet from your home and delivering them to your chosen destination within the UK. Pets Abroad UK provides Door-To-Door service in both the UK and around the world, so whether you need your pet moved to another county, country, or continent, we can help.',
        },
      },
    ],
  },
  petTravelGlobalNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Global Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Reach out to a reputable commercial pet transport service such as Dogtainers. Airlines often recommend or require the use of professional pet transport services when travelling abroad. Additionally, consult your veterinarian for a health check and to verify up-to-date vaccinations, with guidance from Dogtainers on destination-specific requirements. Ensure you have a pet passport or health certificate as needed. Acquire an IATA-compliant travel crate suitable for your pet's size and train them to be comfortable inside. Remove your pet's collar and attach it to the crate. You're ready to go!",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Are Required For Global Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The specific documents needed may vary depending on your destination country. Typically, you will need a pet passport or health certificate, proof of vaccinations, and a microchip registration. Dogtainers will provide information on the specific document requirements for your chosen destination.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process Of Clearing Customs During Global Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "When arriving at your destination, you will need to clear customs with your pet. This involves presenting the required documents and ensuring your pet complies with the destination country's regulations. Customs may inspect your pet and their belongings. Be prepared for potential quarantine procedures. Dogtainers can handle customs clearance in the destination country for you.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Far In Advance Should I Start Planning For Global Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We recommend getting in touch with us at least one month before your preferred travel date for global pet transport, but, the earlier the better. This allows time for vaccinations, paperwork, and any necessary preparations. Some countries have specific waiting periods or quarantine requirements that you should be aware of. Dogtainers can provide you specific advice and a timeline to follow based on your pet and destination country.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Quarantine Regulations For Pets Travelling Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Quarantine regulations vary by country. Some countries have strict quarantine requirements, while others may not require it at all. Dogtainers will let you know the quarantine requirements of your destination country and help you plan accordingly.',
        },
      },
      {
        '@type': 'Question',
        name: "What Should I Pack In My Pet's Travel Kit For The Journey?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Anything your pet is likely to need! We recommend including the essentials such as food, medications, a leash, collar with ID tags, and any comfort items like their favourite toy or blanket.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Pet-Friendly Airlines For International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes. Many airlines allow pets to travel internationally, but the policies and fees can vary. Dogtainers can advise you on each airline’s pet travel policy and fees. We only fly with airlines that prioritise the safety and comfort of pets, but if you have a preferred airline with a satisfactory pet-travel policy, we are more than willing to accommodate your choice.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do You Handle Pet Transport To Different Countries With Varying Regulations?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Handling pet transport to another country can be complex. Ensuring your pet adheres to their import prerequisites, which usually involve a health certificate or pet passport, vaccinations, blood tests, and additional health treatments, is essential. For a streamlined process, consider using a professional pet transport service, like Dogtainers. Dogtainers specialise in international pet travel and can help ensure a successful and compliant pet relocation to your destination country.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Do If My Pet Has Special Medical Needs During The Journey?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If your pet has special medical needs, consult with your veterinarian before the journey. Ensure you have an adequate supply of medications and any necessary medical records. Consider carrying a first aid kit tailored to your pet's needs, and inform airline staff of any special requirements to ensure your pet's comfort and safety during travel.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportCanadaGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Transport My Pet To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The first step in transporting your pet to Canada is to get in contact with a reputable pet transport company. Many airlines recommend and some require you to work with a commercial pet transport company in order to fly internationally. Your pet transporter will organise flights, create a timeline of key dates, and inform you of any import requirements. But in general, to fly to Canada you will need a Pet Passport or health certificate, and your pet’s crate must be IATA-specified.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Pet Entry Requirements For Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To bring your pet to Canada, you'll need proof of a current rabies vaccination or a health certificate. The certificate should clearly identify your pet and must bear the endorsement of a government veterinarian. Additional documentation may be required, depending on your pet's age and your ownership status.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Fly With My Pet To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can fly with your pet to Canada. Flying is a popular choice for pet travel due to its speed, availability, and affordability. Small cats and dogs can even fly in the cabin. However, certain pets, such as extra-large breeds, brachycephalic or snub-nosed breeds, or particularly anxious animals, may require additional accommodations to fly.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does Pet Transport To Canada Cost?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The cost of airline pet transport to Canada can vary depending on various factors, including the time of year, the size of your pet, and the distance. It's recommended to request a quote from a reputable pet transport service provider for an accurate Canada pet travel cost estimate. Pets Abroad UK offers free, no-obligation quotes for pet travel to Canada.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Quarantine Rules For Pets In Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Canada does not have quarantine rules for pets if you follow the established import regulations. Ensuring your pet meets the necessary health certifications and vaccination requirements is crucial to avoiding quarantine.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents Do I Need For Pet Travel To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "For pet travel to Canada, you'll typically need proof of a current rabies vaccination and a health certificate. Additionally, there may be specific requirements for puppies and kittens traveling without their owner.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport Multiple Pets To Canada At The Same Time?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can transport multiple pets to Canada at the same time. However, this may require additional planning and accommodations. It's advisable to consult with a pet transport service at least one month in advance to ensure a smooth process.",
        },
      },
      {
        '@type': 'Question',
        name: "How Can I Ensure My Pet's Comfort During International Travel?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To ensure your pet's well-being during international travel, opt for a carrier that provides ample space for them to sit, stand, and turn around with ease. Feel free to include familiar items like toys or bedding if your airline permits, and make sure to adhere to any particular guidelines provided by your selected pet transport service. It's advisable not to feed your pet within 8 hours of the journey to prevent potential stomach upset, and only consider sedation if it's recommended by a veterinarian and approved by the airline.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Airlines That Allow Pets In-Cabin To Canada?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Airlines that allow pets in-cabin to Canada can vary, and their specific policies may change. Some well-known airlines that have allowed in-cabin pet travel include Air Canada and WestJet, but it's important to check with the airline for the most up-to-date information.",
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Need A Pet Transport Service For Travelling To Canada With My Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It depends on the airline and your capacity to guarantee a compliant trip. Failure to meet the necessary import requirements can lead to scenarios where your pet may be denied boarding or subjected to up to four months of quarantine upon arriving in Canada. This is why airlines highly recommend or even require the involvement of pet relocation companies for international pet travel.',
        },
      },
    ],
  },
  dogTransportGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Much Does Dog Transport Cost In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of dog transport in the UK can vary depending on various factors such as the distance travelled, mode of transportation, size of the dog, and additional services required. Generally, dog transport services in the UK can range from £150 to £500 or more for domestic travel.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Dog Transport Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "When transporting a dog within the UK, there are a few requirements to consider. Dogs must be microchipped and have an up-to-date rabies vaccination if travelling from abroad. Additionally, it's important to ensure that the dog is safely secured in a suitable carrier or crate during transportation.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Travel With My Dog On Public Transport In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes. In general, you can travel with one dog on public transport in the UK, but it depends on the specific regulations of the transport provider. Different modes of public transport may have varying rules regarding dog transport, including requirements for muzzles, leashes, or the size of the dog. It's advisable to check with the relevant transport company beforehand to understand their policies.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Specific Regulations For Flying With Dogs In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are specific regulations for flying with dogs in the UK. If you're travelling within the UK, the airline you choose will have its own policies regarding pet travel. For international flights, the rules and regulations of the destination country will also apply. It's essential to contact the airline well in advance to understand their requirements, such as crate specifications, health certificates, and any additional documentation.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Ensure The Safety Of My Dog During Transport In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To ensure the safety of your dog during transport in the UK, there are several steps you can take. First, use a secure and appropriately-sized dog travel carrier that allows your dog to have enough space to stand, turn, and lie down comfortably. Ensure proper ventilation and provide bedding inside the crate for added comfort. It's crucial to securely fasten the crate to prevent it from shifting or tipping during transportation. Be mindful of extreme temperatures and avoid subjecting your dog to harsh conditions. If you select a UK dog travel service, choose a reputable company with a proven track record in handling pet transport. For long journeys, plan regular breaks to allow your dog to exercise, drink water, and attend to bathroom needs. These measures will contribute to the overall safety and well-being of your dog during transport in the UK.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Options For Ground Transport For Dogs In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "In the UK, there are various options available for ground transport when it comes to transporting dogs. Pet transport companies, like Pets Abroad UK, specialise in providing dog transportation services. Additionally, there are animal courier services dedicated to transporting pets safely. Certain train and bus services may allow dogs onboard, although there might be specific restrictions and requirements in place. It's important to inquire about any guidelines or regulations when considering these modes of transportation for your dog.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Pet-Friendly Accommodations Available During Dog Transport In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are pet-friendly accommodations available during dog transport in the UK. Many hotels, guesthouses, and vacation rentals have pet-friendly policies, allowing you to stay with your dog. It's recommended to research and book accommodation in advance, ensuring they are aware of your dog's presence and any specific requirements. Consider opting for a kennel as a convenient solution for your dog's accommodation. Pets Abroad UK can assist in arranging kennels for both short-term and long-term stays, ensuring your dog's comfort and well-being during their time away from home.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Transport My Dog Internationally From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, you can transport your dog internationally from the UK. However, each country has its own regulations and requirements for importing pets. These may include specific vaccinations, health certificates, quarantine periods, and other documentation. It's crucial to research the destination country's import regulations and work with a reputable pet transport company, such as Pets Abroad UK, to ensure compliance with all requirements.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Quarantine Requirements For Dog Transport Within The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, there are no quarantine requirements for dog transport within the UK as long as the dog meets the necessary health and vaccination requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Consider When Choosing A Dog Transport Service In The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "When selecting a dog transport service in the UK, look for a reputable and experienced service with a proven track record. Ensure they possess the necessary licences and insurance for legal operation and to safeguard against any incidents. Safety measures should be a priority, so inquire about their protocols for maintaining the well-being and comfort of dogs during transport, including vehicle type, crate requirements, and rest breaks. Consider the services offered, such as door-to-door transportation and veterinary assistance. While pricing is important, don't solely focus on cost; prioritise quality and reliability. Check for responsive customer support and seek recommendations from trusted sources.",
        },
      },
    ],
  },
  internationalPetTransportGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To prepare your pet for international travel, visit the veterinarian for a health check, vaccinations, and treatments. Familiarise them with the travel crate and consider crate training. Include familiar items for comfort. Contact a reputable pet transport company for guidance, documentation assistance, and professional advice for a safe journey.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting Pets Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The requirements for transporting pets internationally vary depending on the destination country. Generally, they include a valid health certificate issued by a licensed veterinarian, up-to-date vaccinations, and sometimes additional tests or treatments specific to the destination's regulations. Microchipping and proper identification are often mandatory as well.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions Or Quarantine Regulations For Importing Pets Into Specific Countries?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, some countries have restrictions or quarantine regulations for importing pets. These regulations can vary widely, ranging from specific vaccination requirements to mandatory quarantine periods upon arrival. It's essential to thoroughly research the import regulations of your destination country and comply with all necessary procedures to avoid any issues or delays.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documents And Vaccinations Are Needed For International Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The required documents for international pet transport typically include a health certificate issued by a veterinarian, proof of vaccinations, and identification documents such as microchip details. The specific vaccinations required can vary depending on the destination, but commonly include rabies vaccination. It's important to work closely with your veterinarian and follow the regulations of the destination country to ensure all necessary documentation and vaccinations are in order.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Choose A Reputable International Pet Transport Company?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When selecting an international pet transport company, consider factors such as their experience in handling international transports, their knowledge of destination country regulations, their network of partners and agents, and customer reviews and testimonials. Look for companies that prioritise the safety, well-being, and comfort of your pet, and offer comprehensive services that meet your specific needs.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does It Take To Transport A Pet Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet transportation is a complex process that requires careful planning and organisation. The duration of the process can vary depending on several factors, particularly the country you are importing your pet to. At a minimum, it typically takes around one month to complete all the necessary preparations and arrangements for international pet transport. However, in some cases, especially when dealing with countries with stringent import regulations, the process can extend up to six months or even longer.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Costs Associated With International Pet Transport?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The costs associated with international pet transport encompass various aspects, including pet flights, veterinary visits, blood work, vaccines, import permits, and, if necessary, boarding and quarantine fees.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Pet-Friendly Airlines Or Specific Flights For Transporting Pets Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Yes, there are several pet-friendly airlines that cater to the transportation of pets internationally. Some airlines allow small pets to travel inside the passenger cabin, provided they meet certain requirements, such as size and weight restrictions. Additionally, many airlines have cargo services specifically designed to transport pets in a safe and controlled environment within the aircraft's cargo hold.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Accompany My Pet During The International Transport Process?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "In some cases, pet owners may be able to accompany their pets during the international transport process. This typically depends on the chosen mode of transportation and the specific policies of the airline or transport company. It's important to inquire about this possibility when making arrangements and to ensure you comply with any requirements or restrictions that may apply.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Ensure The Safety And Well-Being Of My Pet During International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To ensure the safety and well-being of your pet during international travel, it's essential to choose a reputable pet transport company that prioritises pet welfare. Ensure that your pet is adequately prepared for travel, with proper vaccinations, a comfortable travel crate, and familiar items for comfort. Stay informed about the destination country's regulations and requirements, and work closely with your veterinarian and the transport company to address any concerns or specific needs your pet may have.",
        },
      },
    ],
  },
  internationalPetTransportPetTransportUSAGB: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Transporting A Pet To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The requirements for transporting a pet to the USA from the UK typically include a microchip and a health certificate issued by an accredited veterinarian. However, if your pet has been in a ‘High-Risk’ country in the last 6 months, there are additional requirements.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport A Pet To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of transporting a pet to the USA from the UK can vary depending on several factors, including the size of the pet, the chosen mode of transport, the distance, additional services required, and any quarantine arrangements. It is best to request a quote from a reputable pet transport company like Pets Abroad UK for an accurate estimate. However, the cost can range from approximately £600 to £1,600 or more.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Airlines Allow Pets To Travel To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Several airlines allow pets to travel from the UK to the USA, including British Airways, Virgin Atlantic, American Airlines, and Delta Air Lines. However, pet policies and restrictions may vary among airlines, so it's essential to check with each airline, or your pet transporter, regarding their specific requirements and availability.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does It Take To Transport A Pet To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The duration of pet transport from the UK to the USA depends on various factors, such as the chosen mode of transport, the distance, and any layovers. Generally, air travel can take around 8-12 hours, excluding additional procedures.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Need A Pet Passport For My Pet To Travel To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The USA does not recognise the EU pet passport. Instead, you will need to ensure that your pet meets the specific import requirements of the USA, which may include a health certificate and other documentation. It's essential to consult with your veterinarian and research the latest import regulations for pets traveling to the USA.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For Travel To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To prepare your pet for travel to the USA, ensure they are up-to-date on vaccinations, obtain necessary documentation, acclimate them to the travel crate, and familiarise them with new environments. Crate training, exposure to car rides, and positive reinforcement can help reduce anxiety. Seek guidance from professionals or reputable pet transport companies for detailed preparation tips.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Travel With My Pet To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can travel with your pet to the USA from the UK. However, specific regulations, restrictions, and requirements apply. These may vary depending on the mode of transport and the airline you choose. Contact a pet transport company to understand the guidelines and make appropriate arrangements.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Quarantine Requirements For Pets Traveling To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "The USA typically does not impose a general quarantine requirement for pets traveling from the UK. However, certain circumstances, such as incomplete documentation or health concerns, may result in the need for quarantine or additional measures. It's crucial to research and comply with the latest pet import regulations to avoid any complications.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Restrictions On Breeds Of Pets That Can Travel To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "There are generally no nationwide restrictions on specific breeds of pets traveling from the UK to the USA. However, it's important to note that some states and municipalities within the USA may have their own breed-specific legislation or regulations in place. It's recommended to consult with a professional pet transport company, like Pets Abroad UK, for the most up-to-date information on any breed restrictions or regulations that may apply during the pet transport process.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Track My Pet During Transport To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Many reputable pet transport companies provide tracking services to keep you updated on your pet's journey. They may offer real-time tracking or regular updates on your pet's location and well-being. You can inquire with the chosen transport provider about their tracking options and any additional fees associated with it.",
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation Do I Need For My Pet To Travel To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "To travel to the USA from the UK with your pet, you will need a microchip for identification and a health certificate issued by an accredited veterinarian within 10 days of travel. Additional documentation may be required based on specific airline and destination country regulations. It's advisable to consult with your veterinarian and a reputable pet transport company like Pets Abroad UK to ensure you have all the necessary paperwork for a smooth journey.",
        },
      },
      {
        '@type': 'Question',
        name: 'Can My Pet Travel In A Cabin With Me To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Whether your pet can travel in the cabin with you to the USA from the UK depends on various factors, including the size of your pet, airline regulations, and the availability of cabin space. Some airlines allow small pets to travel in the cabin under certain conditions, such as using an airline-approved carrier that fits under the seat. However, larger pets will generally need to travel in the cargo hold. It's important to check with the airline for their specific policies regarding in-cabin pet travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Age Restrictions For Pets Traveling To The USA From The UK?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Most airlines require pets to be at least 8 weeks old to travel. However, for certain international pet flights, particularly long-haul journeys, airlines may have a minimum age requirement of 12 weeks. It's important to check with the specific airline you plan to travel with for their age restrictions and any additional requirements they may have for transporting young pets. Older pets may require additional considerations and a health check to ensure they can handle the journey comfortably. It's advisable to consult with your veterinarian before making travel arrangements for your pet.",
        },
      },
    ],
  },
  petTravelGlobal: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How Can I Travel With My Pet Internationally?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'To travel internationally with your pet, ensure your pet meets the vaccination and health standards of the destination country, obtain necessary permits, and follow specific documentation requirements. Most airlines recommend or require you to work with a pet transport company, like Dogtainers, for international pet travel.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Flying With A Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Particular health requirements depend on the destination country but typically include a health certificate, microchip, and up-to-date vaccinations. Airlines require pets to be fit and healthy and to travel in an IATA-specified crate. They may also have age restrictions, like pets being over 8 weeks or under 12 years of age. When you travel with Dogtainers, we can provide specific information based on your circumstances.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Specific Airlines That Allow Pets In The Cabin?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In Australia, no airline permits pets to be in the cabin, unless they are a service animal.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For A Long Road Trip?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Ensure your pet is comfortable with car travel, use a secure carrier or restraint, and plan breaks for water, food, and bathroom stops during the journey. When you travel with Dogtainers, we handle all this for you, and more.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Vaccinations Does My Pet Need For Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It depends. Different countries have different requirements. Some common vaccinations are rabies, F3 for cats, and C5 for dogs.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Bring My Pet On Public Transportation?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Policies regarding pets on public transportation vary. We recommend you check with the specific provider or contact Dogtainers to handle road transport on your behalf.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Restrictions For Travelling With Certain Breeds Of Pets?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, the following dog breeds are banned in Australia and many other countries: American Pit Bull and other Pit Bull Breeds, Fila Brasileiro, Japanese Tosa, Dogo Argentino, and Perro de Presa Canario. Restrictions can be put in place for brachycephalic breeds, like pugs, due to respiratory concerns. Additionally, dogs over 12 may require a health certificate to fly on certain airlines.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Process For Shipping A Pet As Cargo?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Most airlines recommend or require you to work with a pet transport provider, like Dogtainers. To ship a pet as cargo, they will likely need the vaccinations of the destination country, a health certificate, an import permit, and to travel in an IATA-specified crate.',
        },
      },
      {
        '@type': 'Question',
        name: 'Do I Need A Pet Passport For International Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "In most cases, no. While Pet Passports are prevalent within the EU, their usage is not widespread beyond it. Alternative documentation, such as microchip details, health records, and an import permit, is often acceptable. Dogtainers is ready to help you secure the required documents for your pet's travel.",
        },
      },
      {
        '@type': 'Question',
        name: 'How Can I Keep My Pet Calm During Travel?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Familiarise your pet with their carrier, use familiar bedding and toys, and consider calming products or consult with a veterinarian for advice. We do not recommend sedating your pet unless explicitly instructed by a veterinarian.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Are The Quarantine Requirements For Travelling With A Pet?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Quarantine regulations vary based on the destination country. While certain nations mandate mandatory quarantine, others impose it only if import requirements are not met, and some have no quarantine requirements at all. Generally, the stringency depends on the rabies status of the country, with rabies-free nations having rigorous import conditions. Dogtainers can help secure quarantine reservations in all countries, ensuring a journey that aligns with regulatory compliance.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Pet-Friendly Hotels At My Destination?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes! Through Dogtainers’ global network of pet specialists, we can help find you pet-friendly hotels, kennels, and catteries in almost every country around the world.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I Travel With Multiple Pets At Once?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can travel with multiple pets at once. However, each pet will likely need their own crate and all pets must travel in the animal’s only section of the cargo hold.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Should I Include In A Pet Travel Kit?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Pack essentials like food, water, bowls, medications, leash, and health records. Include familiar items for your pet's comfort, like a toy or a blanket.",
        },
      },
      {
        '@type': 'Question',
        name: 'Is It Safe To Fly With A Brachycephalic Breed (Like Bulldogs Or Pugs)?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Flying can pose respiratory risks for brachycephalic breeds, as such, most airlines require you to work with a pet transport company, like Dogtainers, to manage these risks. Your brachy-breed will probably need a slightly larger crate than usual to make their journey more comfortable and ensure easier breathing.',
        },
      },
    ],
  },
  petTransportImportServicesNZ: {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What Are The Requirements For Importing Cats And Dogs Into New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Importing cats and dogs to New Zealand requires specific documentation, health certificates, and microchipping requirements. The number of tests and treatments your pet needs depends on their country of origin. Our detailed guide ensures you meet all the necessary criteria for a hassle-free import process.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Much Does It Cost To Transport Pets To New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The cost of moving pets to NZ depends on factors like the country of origin, pet size, quarantine, and additional services. As an example, the cost of pet transport to New Zealand from Australia is roughly $3000 to $6500 NZD on importing their pet to New Zealand, but this can be higher depending on the factors above. Our transparent pricing structure offers cost-effective solutions tailored to your needs. Get a free, no-obligation quote on our website.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are there any banned or restricted breeds for pet import into New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, the following breeds are banned in New Zealand: Brazilian Fila, Dogo Argentino, Japanese Tosa, Perro de Presa Canario, American Pit Bull Terrier, Hybrid species',
        },
      },
      {
        '@type': 'Question',
        name: 'What Is The Quarantine Process For Pets Arriving In New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The quarantine process for pets in NZ is relatively straightforward. Pets are transported directly from the airport in New Zealand to their pre-planned quarantine facility. They stay in quarantine for at least ten days, or more if they show signs of illness or infection. Pets are isolated from other animals while in quarantine but are monitored and engaged with by staff. When their quarantine period is up, pets are collected from the facility by you or one of Dogtainers NZ’s friendly staff.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Prepare My Pet For International Travel To New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'International pet relocation to New Zealand is a complex and time-consuming task. Most airlines recommend, and many require you to travel with a professional pet transport company like Dogtainers NZ. So you’re aware of how to best prepare your pet for their New Zealand trip, we’ve prepared an in-depth guide to take you through New Zealand’s import requirements.',
        },
      },
      {
        '@type': 'Question',
        name: "Can I Bring My Pet's Own Travel Crate For The Journey To New Zealand?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, you can. Provided your crate is size-appropriate and IATA-specified, you’re more than welcome to use your own travel crate when flying to New Zealand. Just be aware that pets may not be permitted to board by airport staff if they’re in a crate that doesn’t provide ample room for your pet to sit, stand, and turn around in. We provided measurement instructions on our website.',
        },
      },
      {
        '@type': 'Question',
        name: 'What Documentation And Health Certificates Are Needed For Pet Import To New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It depends on where your pet is travelling from. For pets coming from Australia, you will only need Model Veterinary Certificates A and B that have been properly declared. For pets coming from a Group 2 country, you will also need booking confirmation of a quarantine facility and an import permit. For Group 3, you will also need an Official Veterinarian Declaration (OVD).',
        },
      },
      {
        '@type': 'Question',
        name: 'How Long Does The Quarantine Period Last For Pets In New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pet quarantine in New Zealand lasts at least ten days but may be extended if your pet shows signs of infection or illness.',
        },
      },
      {
        '@type': 'Question',
        name: 'Are There Any Special Considerations For Transporting Senior Pets To New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'There may be, yes. Airlines generally require a certificate of good health from a vet after pets reach a certain age. As well as this, direct flights during milder times of the year and day can also help to reduce impact on senior pets. When you fly with Dogtainers NZ, we consider all this and more to ensure we select the best flight for your senior pet.',
        },
      },
      {
        '@type': 'Question',
        name: "What Safety Measures Are In Place To Ensure My Pet's Well-Being During The Journey To New Zealand?",
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pets are last to be loaded onto the plane and first to be taken off. This helps reduce the time they’re in a noisy environment. Pets travel in an animal-only section of the cargo hold that’s been dimmed and set to 18 degrees Celsius to help them rest. Finally, all animals travel in IATA-specified travel crates to ensure they are sturdy, have proper ventilation, and secure locking mechanisms.',
        },
      },
      {
        '@type': 'Question',
        name: 'How Do I Take My Pet’s Medication Into New Zealand?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your pet is taking any medication, you must send MPI a copy of the prescription before or when your pet arrives in New Zealand. If the veterinary medicine is travelling with your pet, you must complete a Declaration of Medicines. Attach the declaration and a copy of the prescription to your other import documents. Generally, MPI will give clearance for up to a 3-month supply.',
        },
      },
    ],
  },
} as const;

const locationMetaData = {
  '/location/brisbane-bne/': {
    image:
      'https://www.dogtainers.com.au/static/062800ebadc6492ef419f390d606bf79/a35de/brisbane_team.webp',
    id: 'https://www.dogtainers.com.au/#brisbane-bne',
    streetAddress: '36 Harvey Street North',
    addressLocality: 'Eagle Farm',
    addressRegion: 'QLD',
    postalCode: '4009',
    addressCountry: 'AU',
    latitude: -27.432701,
    longitude: 153.0922418,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:45',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
  '/location/sydney-syd/': {
    image:
      'https://www.dogtainers.com.au/static/73a4b15d779216b615904b4f67df4a36/31706/sydney_team.webp',
    id: 'https://www.dogtainers.com.au/#sydney-syd/',
    streetAddress: '10 Clevedon St',
    addressLocality: 'Botany',
    addressRegion: 'NSW',
    postalCode: '2019',
    addressCountry: 'AU',
    latitude: -33.94817396927486,
    longitude: 151.20242888102663,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:45',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
  '/location/melbourne-mel/': {
    image:
      'https://www.dogtainers.com.au/static/cf57cc0410bb356a633c61f384f6bf5f/9c00f/Melbourne-team-closeup.webp',
    id: 'https://www.dogtainers.com.au/#melbourne-mel/',
    streetAddress: '11/189B S Centre Rd',
    addressLocality: 'Tullamarine',
    addressRegion: 'VIC',
    postalCode: '3043',
    addressCountry: 'AU',
    latitude: -37.70410572924971,
    longitude: 144.85734018833995,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:30',
        closes: '17:00',
      },
    ],
  },
  '/location/perth-per/': {
    image:
      'https://www.dogtainers.com.au/static/aad2a87849d95ae2fb4495f638a8d503/7737f/perthTeam.webp',
    id: 'https://www.dogtainers.com.au/#perth-per/',
    streetAddress: '9/184 Raleigh St',
    addressLocality: 'Carlisle',
    addressRegion: 'WA',
    postalCode: '6101',
    addressCountry: 'AU',
    latitude: -31.98643488396238,
    longitude: 115.92276411151188,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        opens: '07:30',
        closes: '16:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '08:30',
        closes: '16:00',
      },
    ],
  },
  '/location/adelaide-adl/': {
    image:
      'https://www.dogtainers.com.au/static/19796dae7182fa9f250e466ea92cfa90/892f8/iStock-1163740036.webp',
    id: 'https://www.dogtainers.com.au/#adelaide-adl/',
    streetAddress: '2/14 Bruce Av',
    addressLocality: 'Marleston',
    addressRegion: 'SA',
    postalCode: '5031',
    addressCountry: 'AU',
    latitude: -34.90357401064436,
    longitude: 138.55825662691322,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:30',
        closes: '19:15',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:30',
        closes: '12:00',
      },
    ],
  },
  '/location/darwin-drw/': {
    image:
      'https://www.dogtainers.com.au/static/836034aebdbc0d29ad9beb9a6c2ff16c/31706/shutterstock_274178327.webp',
    id: 'https://www.dogtainers.com.au/#darwin-drw/',
    streetAddress: '5/4 Durand Ct',
    addressLocality: 'Coconut Grove',
    addressRegion: 'NT',
    postalCode: '0810',
    addressCountry: 'AU',
    latitude: -16.878237346490437,
    longitude: 145.7185056200626,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:00',
        closes: '16:30',
      },
    ],
  },
  '/location/cairns-cns/': {
    image:
      'https://www.dogtainers.com.au/static/16e44982e761d3ca12fcb51945a224f8/2b03a/Cairns_team_photo.webp',
    id: 'https://www.dogtainers.com.au/#cairns-cns/',
    streetAddress: '8 Lee St',
    addressLocality: 'Freshwater',
    addressRegion: 'QLD',
    postalCode: '4870',
    addressCountry: 'AU',
    latitude: -16.878237346490437,
    longitude: 145.7185056200626,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:00',
        closes: '16:30',
      },
    ],
  },
  '/location/albury-abx/': {
    image:
      'https://www.dogtainers.com.au/static/3fd607ed18e71f3001a456d7e15a867b/a1331/jeremy-perkins-ynM02vz5raA-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#albury-abx/',
    streetAddress: '121 Airport Dr',
    addressLocality: 'East Albury',
    addressRegion: 'NSW',
    postalCode: '2640',
    addressCountry: 'AU',
    latitude: -36.069439462741784,
    longitude: 146.9546392909092,
  },
  '/location/alice-springs-asp/': {
    image:
      'https://www.dogtainers.com.au/static/640a69e7c472c9c9b67a6bf2bdd338a4/31706/james-barker-v3-zcCWMjgM-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#alice-springs-asp/',
    streetAddress: 'Santa Teresa Road',
    addressLocality: 'Alice Springs NT Albury',
    addressRegion: 'NT',
    postalCode: '0871',
    addressCountry: 'AU',
    latitude: -23.801541374057575,
    longitude: 133.903453986186,
  },
  '/location/auckland-akl/': {
    image:
      'https://www.dogtainers.com.au/static/99c1aa4a46a2c7d89dc3c8e838f92703/bd9a0/dogtainers-auckland-team.webp',
    id: 'https://www.dogtainers.com.au/#auckland-akl/',
    streetAddress: '610 Burtt Road',
    addressLocality: 'Runciman',
    postalCode: '2578',
    addressCountry: 'NZ',
    latitude: -37.12135411140678,
    longitude: 174.94065311537258,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:30',
        closes: '17:30',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
  '/location/canberra-cbr/': {
    image:
      'https://www.dogtainers.com.au/static/2a364b62133ec22c44e7e503ef607b34/31706/kojirou-sasaki-AE4nDmxQYtI-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#canberra-cbr/',
    streetAddress: 'Terminal Cct',
    addressLocality: 'Pialligo',
    addressRegion: 'ACT',
    postalCode: '2609',
    addressCountry: 'AU',
    latitude: 35.3093032,
    longitude: 149.1902503,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:45',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
  '/location/hobart-hba/': {
    image:
      'https://www.dogtainers.com.au/static/478b6c0d1829534dc61419d2d4a8e934/31706/yuki-dog-BegIKNC4-Zk-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#hobart-hba/',
    streetAddress: 'Strachan St',
    addressLocality: 'Cambridge TAS',
    addressRegion: 'TAS',
    postalCode: '7170',
    addressCountry: 'AU',
    latitude: -42.838813,
    longitude: 147.5060594,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:45',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
  '/location/townsville-tsv/': {
    image:
      'https://www.dogtainers.com.au/static/ac09ae791c60bd6940e15ef0eb2306f7/f71b6/greg-lippert-Ssmp27P6NsI-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#townsville-tsv/',
    streetAddress: 'Coral Sea Dr',
    addressLocality: 'Garbutt',
    addressRegion: 'QLD',
    postalCode: '4814',
    addressCountry: 'AU',
    latitude: -19.2563197,
    longitude: 146.7711617,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:00',
        closes: '16:30',
      },
    ],
  },
  '/location/wellington-wlg/': {
    image:
      'https://www.dogtainers.com.au/static/c6942da8a6f3376f98ba511cab9eb851/31706/andrew-lancaster-k34a6Yzt6A0-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#wellington-wlg/',
    streetAddress: 'Stewart Duff Drive',
    addressLocality: 'Rongotai, Wellington',
    postalCode: '6022',
    addressCountry: 'NZ',
    latitude: -41.3305202,
    longitude: 174.8120662,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '09:00',
        closes: '17:00',
      },
    ],
  },
  '/location/christchurch-chc/': {
    image:
      'https://www.dogtainers.com.au/static/e835710248d89601f08aa2ce8a15785c/eeed8/jarrod-reed-xK0KQwyqb10-unsplash.webp"',
    id: 'https://www.dogtainers.com.au/#christchurch-chc/',
    streetAddress: '30 Durey Road',
    addressLocality: 'Harewood, Christchurch',
    postalCode: '8053',
    addressCountry: 'NZ',
    latitude: -43.4879482,
    longitude: 172.5399694,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '09:00',
        closes: '17:00',
      },
    ],
  },
  '/location/launceston-lst/': {
    image:
      'https://www.dogtainers.com.au/static/81a610c91d933cfb7e0c29da9f038f4d/31706/vincent-van-zalinge-AjtGg8feZhg-unsplash.webp',
    id: 'https://www.dogtainers.com.au/#launceston-lst/',
    streetAddress: 'U2 208 South Pomona Road',
    addressLocality: 'Trevallyn',
    addressRegion: 'TAS',
    postalCode: '7250',
    addressCountry: 'AU',
    latitude: -41.43340999999999,
    longitude: 147.1068126,
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:45',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Saturday',
        opens: '08:00',
        closes: '12:00',
      },
    ],
  },
};

const generateFaqPageMeta = (faqBlock: BlockFaq): LdJsonScript => ({
  type: 'application/ld+json',
  content: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqBlock.items
      .filter((i) => !!i.title && !!i.content)
      .map(({ title, content }) => ({
        '@type': 'Question',
        name: title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: content,
        },
      })),
  },
});

const videoMeta = {
  AU: {
    content: {
      name: 'Dogtainers & Virgin Australia Domestic / Interstate Pet Travel',
      description:
        'How Dogtainers and virgin work together to transport our fur babies around Australia and the world',
      thumbnailUrl: 'https://i.ytimg.com/vi_webp/e0RNdYfAVuc/sddefault.webp',
      uploadDate: '2018-03-29T08:00:00+02:00',
      duration: 'PT1M33S',
      contentUrl: 'https://www.youtube.com/watch?v=e0RNdYfAVuc',
      embedUrl: 'https://www.youtube.com/embed/e0RNdYfAVuc',
      interactionCount: '1710',
    },
  },
  NZ: {
    content: {
      name: 'Dogtainers Domestic / Interstate Pet Travel',
      description:
        'How Dogtainers and Virgin airlines work together to transport our fur babies around New Zealand and the world.',
      thumbnailUrl: 'https://youtu.be/e0RNdYfAVuc',
      uploadDate: '2017-03-29',
      duration: 'PT1M12S',
      contentUrl: 'https://www.youtube.com/watch?v=e0RNdYfAVuc',
      embedUrl: 'https://www.youtube.com/embed/e0RNdYfAVuc',
    },
  },
} as Record<string, Pick<LdJsonScript, 'content'>>;

const generateDomesticVideoMeta = (): LdJsonScript => {
  const content = videoMeta[defaultLocale.slice(-2)];

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      ...content,
    },
  };
};

const webpageMeta = {
  GB: {
    content: {
      name: 'Domestic Pet Transport',
      description:
        "At Pets Abroad UK, we provide domestic pet transport services for pet owners looking to move their furry friends within the United Kingdom. We offer door-to-door delivery, transport crates, and pet-friendly travel arrangements to ensure a smooth and stress-free experience for you and your pet. We prioritize your pet's well-being throughout the entire transportation process and provide useful tips and resources to prepare you and your pet for the journey. Contact us today for a free, no-obligation quote and let us take care of your pet's transportation needs!",
      publisher: {
        '@type': 'Organization',
        name: 'Pets Abroad UK',
      },
    },
  },
  NZ: {
    content: {
      name: 'Domestic Pet Transport in New Zealand',
      description:
        "At Dogtainers NZ, we provide domestic pet transport services for pet owners looking to move their furry friends within New Zealand. We offer door-to-door delivery, transport crates, and pet-friendly travel arrangements to ensure a smooth and stress-free experience for you and your pet. We prioritize your pet's well-being throughout the entire transportation process and provide useful tips and resources to prepare you and your pet for the journey. Contact us today for a free, no-obligation quote and let us take care of your pet's transportation needs! ",
      publisher: {
        '@type': 'Organization',
        name: 'Dogtainers NZ',
      },
    },
  },
} as Record<string, Pick<LdJsonScript, 'content'>>;

const generateDomesticImageMeta = (block: string): LdJsonScript => {
  const { content } =
    defaultLocale === 'en-GB' || defaultLocale === 'en-NZ'
      ? webpageMeta[defaultLocale.slice(-2)]
      : typesOfImageMeta[`${block}` as keyof typeof typesOfImageMeta];

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      ...content,
    },
  };
};

const generateLocationMeta = (name: Block, path: string): LdJsonScript => {
  const key = path.endsWith('/') ? path : `${path}/`;
  const {
    id,
    streetAddress,
    addressCountry,
    addressLocality,
    addressRegion,
    postalCode,
    latitude,
    longitude,
    image,
    openingHoursSpecification,
  } = locationMetaData[
    `${key}` as keyof typeof locationMetaData
  ] as LocationMeta;

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: name['title' as keyof typeof name],
      image,
      '@id': id,
      url: `https://www.dogtainers.com.au${key}`,
      telephone: '1300 13 52 52',
      priceRange: '$',
      address: {
        '@type': 'PostalAddress',
        streetAddress,
        addressLocality,
        ...(!!addressRegion && { addressRegion }),
        postalCode,
        addressCountry,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude,
        longitude,
      },
      ...(!!openingHoursSpecification && { openingHoursSpecification }),
      sameAs: [
        'https://www.facebook.com/DogtainersPetTransport/',
        'https://www.instagram.com/dogtainerspettravel/',
        'https://www.youtube.com/channel/UCZFud7-5fOJ3khwBpCFzcjQ/',
      ],
    },
  };
};

const generateCareerDrive = (): LdJsonScript => ({
  type: 'application/ld+json',
  content: {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: 'Domestic Pet Travel Driver',
    description:
      '<p>Animal Handler / Driver supporting both the Domestic & International Divisions, this role is more than just a driver. With a high degree of empathy, you will be at the forefront of meeting clients and ensuring that their pet/animal is transported in the picture of health. A suitable candidate for the role will have high attention to detail and some knowledge or ability to learn about working within the airline industry and animal handling skills. </p>',
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Dogtainers',
      sameAs: 'https://www.dogtainers.com.au/',
      logo: 'https://www.dogtainers.com.au/static/01b20a88435c7f0ae8c16095e05df405/b994d/logo_en-AU.webp',
    },
    industry: 'Pet Transport',
    employmentType: 'FULL_TIME',
    datePosted: '2022-03-01',
    validThrough: '2022-12-31',
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '36 Harvey Street North',
        addressLocality: 'Eagle Farm',
        addressRegion: 'QLD',
        postalCode: '4009',
        addressCountry: 'AU',
      },
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'AUD',
      value: {
        '@type': 'QuantitativeValue',
        minValue: 50000,
        maxValue: 80000,
        unitText: 'YEAR',
      },
    },
  },
});

const generateIpataMeta = (): LdJsonScript => ({
  type: 'application/ld+json',
  content: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Dogtainers',
    alternateName: 'IPATA - Dogtainers',
    url: 'https://www.dogtainers.com.au/',
    logo: 'https://www.dogtainers.com.au/static/01b20a88435c7f0ae8c16095e05df405/b994d/logo_en-AU.webp',
    sameAs: [
      'https://www.facebook.com/DogtainersPetTransport/',
      'https://www.youtube.com/channel/UCZFud7-5fOJ3khwBpCFzcjQ',
      'https://twitter.com/dogtainers/',
      'https://www.instagram.com/dogtainerspettravel/?hl=en',
    ],
  },
});

const generateWebPageSchema = (pageId: string): LdJsonScript => {
  const { name, description, publisherName } =
    typesOfWebSchema[`${pageId}` as keyof typeof typesOfWebSchema];

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name,
      description,
      publisher: {
        '@type': 'Organization',
        name: publisherName,
      },
    },
  };
};

const generateFAQ = (pageId: string): LdJsonScript => ({
  type: 'application/ld+json',
  content: typesOfFAQ[`${pageId}` as keyof typeof typesOfFAQ],
});

const homeMeta = {
  AU: {
    content: {
      name: 'Dogtainers',
      alternateName: 'DogtainersAU',
      url: 'https://www.dogtainers.com.au/',
      logo: 'https://www.dogtainers.com.au/static/01b20a88435c7f0ae8c16095e05df405/b994d/logo_en-AU.webp',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        bestRating: '5',
        worstRating: '1',
        ratingCount: '889',
        url: 'https://www.productreview.com.au/listings/dogtainers',
      },
      sameAs: [
        'https://www.facebook.com/DogtainersPetTransport/',
        'https://www.instagram.com/dogtainerspettravel/',
        'https://www.youtube.com/channel/UCZFud7-5fOJ3khwBpCFzcjQ/',
      ],
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+61 300 13 52 52',
        contactType: 'customer service',
        contactOption: 'TollFree',
        areaServed: 'AU',
        availableLanguage: 'en',
      },
    },
  },
  GB: {
    content: {
      name: 'Pets Abroad UK',
      legalName: 'Pets Abroad UK Ltd.',
      url: 'https://www.petsabroaduk.co.uk/',
      logo: 'https://www.petsabroaduk.co.uk/static/8004faf92e9a0d119ed53cec96c60777/9809c/logo_en-GB.webp',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'The Mill, Horton Rd',
        addressLocality: 'Stanwell Moor',
        addressRegion: 'Staines',
        postalCode: 'TW19 6BF',
        addressCountry: 'United Kingdom',
      },
      sameAs: ['https://www.facebook.com/PetsAbroaduk/ '],
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '[+44 800 002 9110]',
        email: 'info@petsabroaduk.co.uk',
      },
    },
  },
  NZ: {
    content: {
      name: 'Dogtainers NZ',
      legalName: 'Dogtainers Pty Ltd',
      url: 'https://www.dogtainers.co.nz/',
      logo: 'https://www.dogtainers.co.nz/static/ed81e7de34556145ffb5c85a1d200263/b994d/logo_en-AU.webp',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '610 Burtt Rd',
        addressLocality: 'Runciman',
        addressRegion: 'Auckland',
        postalCode: '2578',
        addressCountry: 'New Zealand',
      },
      sameAs: ['https://www.facebook.com/DogtainersPetTransport/'],
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '[+64 800 13 52 52]',
        email: 'auckland@dogtainers.co.nz',
      },
    },
  },
} as Record<string, Pick<LdJsonScript, 'content'>>;

const generateHomeMeta = (home: boolean): LdJsonScript => {
  const {
    content: { sameAs, contactPoint, ...rest },
  } = homeMeta[defaultLocale.slice(-2)];

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      ...rest,
      ...(home && { sameAs }),
      ...(home && { contactPoint }),
    },
  };
};

const mapPageIdToHreflangTags: HreflangPagesTags = {
  home: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/',
    },
  ],
  aboutUs: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/about-us/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/about-us/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/zh-CN/about-us/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/ja-JP/about-us/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/about-us/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/about-us/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/about-us/',
    },
  ],
  birdTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/bird-transport/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/bird-transport/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/bird-transport/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/bird-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/',
    },
  ],
  dogTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/dog-transport/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/dog-transport/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/dog-transport/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/dog-transport/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/dog-transport/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/dog-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/dog-transport/',
    },
  ],
  flyPets: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/fly-pets/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/fly-pets/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/fly-pets/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/fly-pets/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/fly-pets/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/fly-pets/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/fly-pets/',
    },
  ],
  importingPetsToAustralia: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/importing-pets-to-australia/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/importing-pets-to-australia/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/importing-pets-to-australia/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/importing-pets-to-australia/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/importing-pets-to-australia/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/importing-pets-to-australia/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/importing-pets-to-australia/',
    },
  ],
  frequentlyAskedQuestions: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/frequently-asked-questions/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/frequently-asked-questions/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/frequently-asked-questions/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/frequently-asked-questions/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/frequently-asked-questions/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/frequently-asked-questions/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/frequently-asked-questions/',
    },
  ],
  internationalPetTransportPetTransportNewZealand: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-new-zealand/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/international-pet-transport/pet-transport-new-zealand/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/international-pet-transport/pet-transport-new-zealand/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/international-pet-transport/pet-transport-new-zealand/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-new-zealand/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-new-zealand/',
    },
  ],
  internationalPetTransportPetTransportUnitedKingdom: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-united-kingdom/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/international-pet-transport/pet-transport-united-kingdom/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/international-pet-transport/pet-transport-united-kingdom/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/international-pet-transport/pet-transport-united-kingdom/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-united-kingdom/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-united-kingdom/',
    },
  ],
  internationalPetTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/international-pet-transport/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/international-pet-transport/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/',
    },
  ],
  internationalPetTransportPetTransportUSA: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-usa/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-usa/',
    },
  ],
  movers: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/movers/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/movers/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/movers/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/movers/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/movers/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/movers/',
    },
  ],
  ourCareCodeInternationalServiceGuarantee: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/our-care-code/international-service-guarantee/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/our-care-code/international-service-guarantee/',
    },
  ],
  petFlights: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-flights/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pet-flights/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pet-flights/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/pet-flights/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-flights/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-flights/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-flights/',
    },
  ],
  petTravelGlobal: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-travel-global/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pet-travel-global/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pet-travel-global/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/pet-travel-global/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-travel-global/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-travel-global/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-travel-global/',
    },
  ],
  petTravelPreparation: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-travel-preparation/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pet-travel-preparation/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pet-travel-preparation/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/pet-travel-preparation/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-travel-preparation/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-travel-preparation/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-travel-preparation/',
    },
  ],
  petTravelServices: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-travel-services/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pet-travel-services/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pet-travel-services/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-travel-services/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-travel-services/',
    },
  ],
  petTypes: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-types/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pet-types/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pet-types/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/pet-types/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-types/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-types/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-types/',
    },
  ],
  petsFromQuarantine: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pets-from-quarantine/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/pets-from-quarantine/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/pets-from-quarantine/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/pets-from-quarantine/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pets-from-quarantine/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pets-from-quarantine/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pets-from-quarantine/',
    },
  ],
  shipping: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/shipping/',
    },
    {
      hreflang: 'kr',
      href: 'https://www.dogtainers.com.au/ko-KR/shipping/',
    },
    {
      hreflang: 'cn',
      href: 'https://www.dogtainers.com.au/zh-CN/shipping/',
    },
    {
      hreflang: 'jp',
      href: 'https://www.dogtainers.com.au/ja-JP/shipping/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/shipping/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/shipping/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/shipping/',
    },
  ],
  careers: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/careers/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/careers/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/careers/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/careers/',
    },
  ],
  domesticPetTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/domestic-pet-transport/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/domestic-pet-transport/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.com.nz/domestic-pet-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/domestic-pet-transport/',
    },
  ],
  internationalPetTransportPetTransportEurope: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-europe/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-europe/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-europe/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-europe/',
    },
  ],
  internationalPetTransportPetTransportPapuaNewGuinea: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-papua-new-guinea/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-papua-new-guinea/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-papua-new-guinea/',
    },
  ],
  internationalPetTransportPetTransportPhilippines: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-philippines/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-philippines/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-philippines/',
    },
  ],
  internationalPetTransportPetTransportUnitedArabEmirates: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-united-arab-emirates/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-united-arab-emirates/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-united-arab-emirates/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-united-arab-emirates/',
    },
  ],
  internationalPetTransportPetTransportQatar: [
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-qatar/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-qatar/',
    },
  ],
  internationalPetTransportPetTransportFiji: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-fiji/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-fiji/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-fiji/',
    },
  ],
  internationalPetTransportPetTransportHawaii: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-hawaii/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-hawaii/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-hawaii/',
    },
  ],
  internationalPetTransportPetTransportJapan: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-japan/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-japan/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-japan/',
    },
  ],
  internationalPetTransportPetTransportNorfolkIsland: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-norfolk-island/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-norfolk-island/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-norfolk-island/',
    },
  ],
  internationalPetTransportPetTransportSouthAfrica: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-south-africa/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-south-africa/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-south-africa/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-south-africa/',
    },
  ],
  internationalPetTransportPetTransportSouthKorea: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-south-korea/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-south-korea/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-south-korea/',
    },
  ],
  internationalPetTransportPetTransportBrazil: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-brazil/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/international-pet-transport/pet-transport-brazil/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/international-pet-transport/pet-transport-brazil/',
    },
  ],
  petTransportImportServices: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-transport-import-services/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-transport-import-services/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-transport-import-services/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-transport-import-services/',
    },
  ],
  crateHire: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/crate-hire/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/crate-hire/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/crate-hire/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/crate-hire/',
    },
  ],
  petTravelBreeders: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-travel-breeders/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-travel-breeders/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-travel-breeders/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-travel-breeders/',
    },
  ],
  zooAnimalTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/zoo-animal-transport/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/zoo-animal-transport/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/zoo-animal-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/zoo-animal-transport/',
    },
  ],
  defencePets: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/defence-pets/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/defence-pets/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/defence-pets/',
    },
  ],
  contactUs: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/contact-us/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/contact-us/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/contact-us/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/contact-us/',
    },
  ],
  locationBrisbaneBne: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/',
    },
  ],
  locationSydneySyd: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/sydney-syd/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/sydney-syd/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/sydney-syd/',
    },
  ],
  locationMelbourneMel: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/melbourne-mel/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/melbourne-mel/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/melbourne-mel/',
    },
  ],
  locationPerthPer: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/perth-per/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/perth-per/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/perth-per/',
    },
  ],
  locationAdelaideAdl: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/adelaide-adl/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/adelaide-adl/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/adelaide-adl/',
    },
  ],
  locationDarwinDrw: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/darwin-drw/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/darwin-drw/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/darwin-drw/',
    },
  ],
  locationCairnsCns: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/cairns-cns/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/cairns-cns/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/cairns-cns/',
    },
  ],
  locationAlburyAbx: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/albury-abx/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/albury-abx/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/albury-abx/',
    },
  ],
  locationAliceSpringsAsp: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/alice-springs-asp/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/alice-springs-asp/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/alice-springs-asp/',
    },
  ],
  locationAucklandAkl: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/auckland-akl/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/auckland-akl/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/auckland-akl/',
    },
  ],
  locationChristchurchChc: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/christchurch-chc/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/christchurch-chc/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/christchurch-chc/',
    },
  ],
  locationCanberraCbr: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/canberra-cbr/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/canberra-cbr/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/canberra-cbr/',
    },
  ],
  locationHobartHba: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/hobart-hba/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/hobart-hba/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/hobart-hba/',
    },
  ],
  locationLauncestonLst: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/launceston-lst/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/launceston-lst/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/launceston-lst/',
    },
  ],
  locationTownsvilleTsv: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/townsville-tsv/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/townsville-tsv/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/townsville-tsv/',
    },
  ],
  locationWellingtonWlg: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/location/wellington-wlg/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/location/wellington-wlg/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/location/wellington-wlg/',
    },
  ],
  catTransport: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/cat-transport/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/cat-transport/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/cat-transport/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/cat-transport/',
    },
  ],
  careersDriver: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/careers/driver/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/careers/driver/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/careers/driver/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/careers/driver/',
    },
  ],
  careersSalesConsultant: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/careers/sales-consultant/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/careers/sales-consultant/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/careers/sales-consultant/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/careers/sales-consultant/',
    },
  ],
  dtCorporate: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/dt-corporate/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/dt-corporate/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/dt-corporate/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/dt-corporate/',
    },
  ],
  covid: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/covid/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/covid/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/covid/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/',
    },
  ],
  privacyPolicy: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/privacy-policy/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/privacy-policy/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/privacy-policy/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/privacy-policy/',
    },
  ],
  termsConditions: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/terms-conditions/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/terms-conditions/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/terms-conditions/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/terms-conditions/',
    },
  ],
  petScams: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-scams/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-scams//',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-scams/',
    },
  ],
  petTransportTips: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-transport-tips/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/pet-transport-tips/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/pet-transport-tips/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-transport-tips/',
    },
  ],
  petCalmingProducts: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/pet-calming-products/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/pet-calming-products/',
    },
  ],
  ourCareCode: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/our-care-code/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/our-care-code/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/our-care-code/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/our-care-code/',
    },
  ],
  jetpets: [
    {
      hreflang: 'en-au',
      href: 'https://www.dogtainers.com.au/jetpets/',
    },
    {
      hreflang: 'en-uk',
      href: 'https://www.petsabroaduk.co.uk/jetpets/',
    },
    {
      hreflang: 'en-nz',
      href: 'https://www.dogtainers.co.nz/jetpets/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.dogtainers.com.au/jetpets/',
    },
  ],
  internationalPetTransportPetTransportCanada: [
    {
      hreflang: 'en-GB',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-canada/',
    },
    {
      hreflang: 'x-default',
      href: 'https://www.petsabroaduk.co.uk/international-pet-transport/pet-transport-canada/',
    },
  ],
};

const typesOFTVSeriesMeta = {
  animalsAboardTvSeries: {
    actor: [
      {
        name: 'Dr Harry',
      },
    ],
    author: {
      name: 'Channel 7',
    },
    name: '',
    containsSeason: [
      {
        datePublished: '2023-07-05',
        episode: {
          episodeNumber: '1',
          name: 'Animals Aboard with Dr Harry',
        },
        name: 'Animals Aboard',
        numberOfEpisodes: '8',
      },
    ],
  },
} as Record<string, TVSeriesMeta>;

const generateTVSeriesSchema = (block: string): LdJsonScript => {
  const { actor, author, name, containsSeason } =
    typesOFTVSeriesMeta[`${block}` as keyof typeof typesOFTVSeriesMeta];

  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'TVSeries',
      actor: actor.map(({ name }) => ({
        '@type': 'Person',
        name,
      })),
      author: {
        '@type': 'Organization',
        ...author,
      },
      name,
      containsSeason: containsSeason.map(
        ({ datePublished, episode, ...containsSeason }) => ({
          '@type': 'TVSeason',
          datePublished,
          episode: {
            '@type': 'TVEpisode',
            ...episode,
          },
          ...containsSeason,
        }),
      ),
    },
  };
};

export {
  generateFaqPageMeta,
  generateHomeMeta,
  generateDomesticImageMeta,
  generateDomesticVideoMeta,
  generateLocationMeta,
  generateCareerDrive,
  generateWebPageSchema,
  generateFAQ,
  mapPageIdToHreflangTags,
  generateIpataMeta,
  generateTVSeriesSchema,
};
export type { LdJsonScript };
